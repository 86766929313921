import { http } from '@4beefopag/4bee-fopag-core/dist/services';

export const buscar = async () => {
  const { data } = await http.get('/notificacoes');
  return data;
};

export const confirmarLeitura = async (id) =>
  http.post('/notificacoes/salvar', {
    id_notificacoes: id,
    lido: true,
  });
