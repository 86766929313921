import { mostrarErro } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { defaultPost, getColaboradores, http } from '@4beefopag/4bee-fopag-core/dist/services';
import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calculo } from '../calculo-ticket';

export const reabrirGestor = async (
  empresa,
  local,
  folha,
  competencia,
  observacao,
  dataFinalizacao,
  loading,
  setLoading,
  onFinished
) => {
  setLoading({ ...loading, reabertura: true });

  try {
    await http.post('/beneficios/lancamento/reabrir/solicitar', {
      idEmpresa: empresa,
      idLocal: local,
      idFolha: folha,
      mesCompetencia: moment(competencia, 'DD/MM/YYYY').format('MM'),
      anoCompetencia: moment(competencia, 'DD/MM/YYYY').format('YYYY'),
      observacao,
      dataFinalizacao,
    });

    Modal.success({
      title: 'Sucesso',
      content: (
        <p>Pedido realizado com sucesso, em instantes você receberá um e-mail com a resposta para o seu pedido.</p>
      ),
    });
  } catch (e) {
    if (e.request.status === 400) {
      Modal.error({
        content: e.response.data.message,
      });
    }
  }

  onFinished();

  setLoading({ ...loading, reabertura: false });
};

export const reabrirAdmin = async (empresa, local, folha, competencia, dataLimite, loading, setLoading, onFinished) => {
  setLoading({ ...loading, reabertura: true });

  try {
    await defaultPost(
      {
        idEmpresa: empresa,
        idLocal: local,
        idFolha: folha,
        mesCompetencia: moment(competencia, 'DD/MM/YYYY').format('MM'),
        anoCompetencia: moment(competencia, 'DD/MM/YYYY').format('YYYY'),
        dataLimite,
      },
      '/beneficios/lancamento/reabrir',
      undefined
    );

    onFinished();

    Modal.success({
      title: 'Sucesso',
      content: (
        <p>Pedido reaberto com sucesso, em instantes o gestor responsável receberá um e-mail para reenviar o pedido.</p>
      ),
    });
  } catch (err) {
    mostrarErro(err);
  }

  setLoading({ ...loading, reabertura: false });
};

export const liberarPedidoComplementar = async (
  empresa,
  local,
  folha,
  competencia,
  dataLimite,
  emailUsuario,
  loading,
  setLoading,
  onFinished
) => {
  setLoading({ ...loading, complementar: true });

  try {
    await defaultPost(
      {
        idEmpresa: empresa,
        idLocal: local,
        idFolha: folha,
        mesCompetencia: moment(competencia, 'DD/MM/YYYY').format('MM'),
        anoCompetencia: moment(competencia, 'DD/MM/YYYY').format('YYYY'),
        dataLimite: dataLimite.toISOString(),
        emailUsuario,
      },
      '/beneficios/lancamento/liberarComplementar',
      undefined
    );

    onFinished();

    Modal.success({
      title: 'Sucesso',
      content: (
        <p>
          Pedido complementar liberado com sucesso, em instantes o cliente responsável receberá um e-mail para enviar o
          pedido.
        </p>
      ),
    });
  } catch (err) {
    mostrarErro(err);
  }

  setLoading({ ...loading, complementar: false });
};

/**
 * Apura dias da folha manualmente na competência informada
 * @param {number} folha
 * @param {string} competencia
 * @param {function(number)} setApuracaoProgress
 * @return {Promise<void>}
 */
export const apurarDiasFolha = async (folha, competencia) => {
  const data = moment.utc(competencia, 'DD/MM/YYYY');
  const mes = data.format('MM');
  const ano = data.format('YYYY');
  return new Promise((resolve, reject) => {
    http({
      method: 'POST',
      url: '/colaborador/dias/apurar',
      data: {
        folha,
        mes,
        ano,
      },
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

  // await defaultPost({
  //   folha,
  //   mes: data.month(),
  //   ano: data.year(),
  // }, '/colaborador/dias/apurar');
};

export const useInformacoesCabecalho = (competencia, competenciaFormatada, diaLimiteLancamento) => {
  const defaultValue = {
    peridoUtilizacao: {
      inicio: '-',
      fim: '-',
    },
    peridoLancamento: {
      inicio: '-',
      fim: '-',
    },
  };

  const [informacoesCabecalho, setInformacoesCabecalho] = useState(defaultValue);

  const buscarInformacoesCabecalho = () => ({
    peridoUtilizacao: {
      inicio: moment(competencia).startOf('month').format('DD/MM'),
      fim: moment(competencia).endOf('month').format('DD/MM'),
    },
    peridoLancamento: {
      inicio: 10,
      fim: diaLimiteLancamento,
    },
  });

  useEffect(() => {
    if (!competencia || !diaLimiteLancamento) {
      setInformacoesCabecalho(defaultValue);
    } else {
      setInformacoesCabecalho(buscarInformacoesCabecalho());
    }
  }, [competenciaFormatada, diaLimiteLancamento]);

  return informacoesCabecalho;
};

/**
 * busca competencias por idEmpresa, idLocal e idFolha.
 * @param {number} idEmpresa
 * @param {number} idLocal
 * @param {number} idFolha
 * @return {Promise<*[]|*>}
 */
export const getCompetencias = async (idEmpresa, idLocal, idFolha, setCompetencia) => {
  if (idFolha === undefined) {
    return [];
  }

  try {
    const response = await http.get('/beneficios/lancamento/competencias', {
      params: {
        idEmpresa,
        idLocal,
        idFolha,
      },
    });

    const competenciaAtual = response.data[1];

    if (competenciaAtual) {
      setCompetencia(moment(`${competenciaAtual.mes}/${competenciaAtual.ano}`, 'M/YYYY'));
    }

    return response.data;
  } catch (e) {
    mostrarErro(e);
    return [];
  }
};

/**
 * buscar valor total de tickets lançados pela empresa / folha em
 * uma competência especificada
 * @param empresa
 * @param local
 * @param folha
 * @param competencia
 * @return {Promise<*>}
 */
export const buscarValorTotal = async (empresa, local, folha, competencia) => {
  if (!competencia) {
    return '-';
  }

  const { data: valor } = await http.get('/beneficios/lancamento/buscarValorTotal', {
    params: {
      idInquilino: 0,
      idEmpresa: empresa,
      idLocal: local,
      idFolha: folha,
      competencia,
    },
  });

  return valor;
};

/**
 * Cálculo do lançamento de benefícios
 * @param {(object)} itensCalculo - Valor correspondente a linha do colaborador na tabela
 * @param {Object} regulamento
 * @returns {CalculoTicket} calculo - Objeto com a referência do cálculo
 * (sendo possível usar todas funções da classe de calculo-ticket.js)
 */
export const calcular = (itensCalculo, regulamento) => {
  const { alimentacaoRefeicao } = itensCalculo;
  const calculo = new Calculo(itensCalculo.colaborador, regulamento, itensCalculo.eventos);

  calculo.calcular();

  if (calculo.colaborador.rescisaoProgramada === 1) {
    calculo.eventos.forEach((evento) => {
      // eslint-disable-next-line no-param-reassign
      evento.valor = 0;
    });
  }

  if (calculo.colaborador.rescisaoEfetivada === 1) {
    calculo.eventos.forEach((evento) => {
      // eslint-disable-next-line no-param-reassign
      evento.valor = 0;
    });
  }

  calculo.alimentacaoRefeicao = alimentacaoRefeicao;
  return calculo;
};

export const carregarBeneficios = async (empresa, local, folha, competencia, competenciaFormatada) => {
  if (!competencia) return {};

  const newColaboradores = await getColaboradores(
    {
      idInquilino: 0,
      idEmpresa: empresa,
      idLocal: local,
      idFolha: folha,
      competencia: competenciaFormatada,
    },
    '/beneficios/lancamento'
  );

  if (newColaboradores && newColaboradores.colaboradores && newColaboradores.colaboradores.length > 0) {
    const { regulamento } = newColaboradores;

    newColaboradores.colaboradores = newColaboradores.colaboradores.map((itensCalculo) => {
      try {
        return calcular(itensCalculo, regulamento);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    });
  }
  return { ...newColaboradores, colaboradores: newColaboradores.colaboradores };
};
