export default {
  colunas: {
    dias_adicionais: {
      coluna: 'dias_adicionais',
      descricao: 'Dias Adicionais',
    },
    dias_desconto: { coluna: 'dias_desconto', descricao: 'Dias de Desconto' },
    refeicao_dia: { coluna: 'refeicao_dia', descricao: 'Refeição Dia' },
    comp_valor_refeicao: {
      coluna: 'composicao_tr',
      descricao: 'Compõem Valor Refeição',
    },
    valor_refeicao: { coluna: 'valor_refeicao', descricao: 'Valor Refeição' },
    tranfere_para_alimentacao: {
      coluna: 'tranfere_para_alimentacao',
      descricao: 'Transfere para Alimentação',
    },
    valor_alimentacao_inicial: {
      coluna: 'valor_alimentacao_incial',
      descricao: 'Valor Alimentação Inicial',
    },
    valor_alimentacao: {
      coluna: 'valor_alimentacao',
      descricao: 'Valor Alimentação',
    },
    valor_alimentacao_forcado: {
      coluna: 'valor_alimentacao_forcado',
      descricao: 'Valor Alimentação (Forçado)',
    },
    comp_valor_alimentacao: {
      coluna: 'composicao_ta',
      descricao: 'Compoõem Valor Alimentação',
    },
    quantidade_dias: {
      coluna: 'quantidade_dias',
      descricao: 'Quantidade de dias',
    },
  },
  situacao_tipo: {
    admissao: 'A',
    ferias: 'F',
    demissao: 'D',
    retorno: 'R',
    afastamento: '',
  },
};
