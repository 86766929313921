import React from 'react';
import { Page } from '@4beefopag/4bee-fopag-core/dist/organisms';
import PropTypes from 'prop-types';

const BeneficiosPage = ({ titulo, children }) => (
  <Page
    projeto="Benefícios"
    titulo={titulo}
    telefoneSuporte="5511984571962"
    mensagemSuporte=""
  >
    {children}
  </Page>
);

BeneficiosPage.propTypes = {
  titulo: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default BeneficiosPage;
