import { Avatar, Col, Row, Skeleton } from 'antd';
import { CustomText } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { PreviewTela } from '@4beefopag/4bee-fopag-core/dist/molecules';

const RegulamentosCadastrados = ({ onEditarRegulamento, locais, folhas, regulamentos, loadingRegulamentos }) => {
  const nomeRegulamento = (regulamento) => {
    const splitNome = regulamento.nome_regulamento.split(' ');
    let nome = splitNome[0][0];
    if (splitNome.length > 1) {
      nome = splitNome[0][0] + splitNome[1][0];
    }

    return nome.toUpperCase();
  };

  if (loadingRegulamentos) {
    return (
      <div style={{ marginTop: '80px' }}>
        <Skeleton
          active
          paragraph={{ rows: 3 }}
        />
      </div>
    );
  }

  if (regulamentos && regulamentos.length === 0) {
    return (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 16, offset: 4 }}
          xxl={{ span: 12, offset: 5 }}
          style={{ marginTop: '160px' }}
        >
          <PreviewTela
            imagem="https://storage.googleapis.com/static-files-4fdd9378-835d-40e4-b124-36699ae5cc08/beneficios/regulamento/previewRegulamento.png"
            subTitle="Aqui você terá acesso a todos os Regulamentos de Benefícios cadastrados para a sua empresa."
            title=""
            style={{ padding: '160px 32px !important' }}
          />
        </Col>
      </Row>
    );
  }

  if (regulamentos !== undefined) {
    return (
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          style={{ marginTop: '80px' }}
        >
          <CustomText
            fontSize="14px"
            color="var(--text-grey-8)"
          >
            Regulamentos Cadastrados
          </CustomText>
        </Col>
        {regulamentos.map((regulamento) => (
          <Col
            key={regulamento.id}
            span={24}
            style={{
              padding: '8px 8px 8px 8px',
              backgroundColor: '#fafbfd',
              marginBottom: '16px',
              cursor: 'pointer',
            }}
            onClick={() => onEditarRegulamento(regulamento)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Avatar style={{ backgroundColor: '#fa075a' }}>{nomeRegulamento(regulamento)}</Avatar>
              </div>

              <div
                style={{
                  display: 'grid',
                  gridAutoFlow: 'row',
                  rowGap: '8px',
                  marginLeft: '16px',
                  marginTop: '8px',
                  width: '100%',
                }}
              >
                <CustomText
                  fontSize="12px"
                  color="var(--text-grey-8)"
                  fontWeight="600"
                >
                  {regulamento.nome_regulamento}
                </CustomText>
                <div style={{ display: 'flex' }}>
                  {locais && folhas && (
                    <CustomText
                      fontSize="12px"
                      color="var(--text-grey-6)"
                    >
                      {locais.find((l) => l.idLocal === regulamento.id_local) && (
                        <>{locais.find((l) => l.idLocal === regulamento.id_local).descricao}</>
                      )}
                      {folhas.find((f) => f.idFolha === regulamento.id_folha) && (
                        <> / {folhas.find((f) => f.idFolha === regulamento.id_folha).descricao}</>
                      )}
                    </CustomText>
                  )}
                  <CustomText
                    fontSize="12px"
                    color="var(--text-grey-5)"
                    style={{
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    {`${regulamento.mes_vigencia}/${regulamento.ano_vigencia}`}
                  </CustomText>
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'flex-end',
                  marginLeft: 'auto',
                  paddingLeft: '8px',
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }

  return <></>;
};

RegulamentosCadastrados.propTypes = {
  onEditarRegulamento: PropTypes.func.isRequired,
  locais: PropTypes.arrayOf(
    PropTypes.shape({
      idLocal: PropTypes.number,
      descricao: PropTypes.string,
    })
  ),
  folhas: PropTypes.arrayOf(
    PropTypes.shape({
      idFolha: PropTypes.number,
      descricao: PropTypes.string,
    })
  ),
  regulamentos: PropTypes.arrayOf(PropTypes.object.isRequired),
  loadingRegulamentos: PropTypes.bool,
};

RegulamentosCadastrados.defaultProps = {
  locais: [],
  folhas: [],
  regulamentos: [],
  loadingRegulamentos: false,
};

export default RegulamentosCadastrados;
