import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const TextoModal = styled.p`
  font-size: 12px;
  color: #a3a4a5;
`;

const ModalStyled = styled(Modal)`
  .ant-modal-footer {
    border-top: unset;
  }
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    font-size: 16px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }
`;

const ModalReaberturaGestor = ({ visible, onOk, onCancel, loading }) => {
  const [observacao, setObservacao] = useState('');

  return (
    <ModalStyled
      open={visible}
      title="Reabertura"
      onOk={onOk}
      onCancel={onCancel}
      closeable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk(observacao)}
          loading={loading}
        >
          Enviar
        </Button>,
        <Button
          key="back"
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>,
      ]}
    >
      <TextoModal>Para finalizar o pedido, justifique o motivo da reabertura:</TextoModal>
      <TextArea
        rows={5}
        value={observacao}
        onChange={({ target: { value } }) => setObservacao(value)}
      />
    </ModalStyled>
  );
};

ModalReaberturaGestor.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ModalReaberturaGestor.defaultProps = {
  visible: false,
};

export default ModalReaberturaGestor;
