import React from 'react';
import PropTypes from 'prop-types';
import { CustomText, InputMoney } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { LabelSituacao } from '@4beefopag/4bee-fopag-core/dist/molecules';

const LinhaTabelaComplementar = ({ dadosDaLinha, setValueField, permiteEditar }) => (
  <tr>
    <td>
      <CustomText
        fontSize="12px"
        color="var(--text-grey-8)"
        height="16px"
      >
        {dadosDaLinha.colaborador.cpfContratado}
      </CustomText>
    </td>
    <td>
      <LabelSituacao
        nome={dadosDaLinha.colaborador.nome}
        descricaoSituacao={dadosDaLinha.colaborador.descricaoSituacao}
        situacao={dadosDaLinha.colaborador.codigoSituacao}
      />
    </td>
    <td>
      <InputMoney
        min={0}
        value={dadosDaLinha.valorAlimentacao}
        getValue={setValueField(dadosDaLinha, 'valorAlimentacao')}
        disabled={!permiteEditar}
      />
    </td>
    <td>
      <InputMoney
        min={0}
        value={dadosDaLinha.valorRefeicao}
        getValue={setValueField(dadosDaLinha, 'valorRefeicao')}
        disabled={!permiteEditar}
      />
    </td>
  </tr>
);

LinhaTabelaComplementar.propTypes = {
  dadosDaLinha: PropTypes.shape(PropTypes.object.isRequired),
  setValueField: PropTypes.func,
  permiteEditar: PropTypes.bool.isRequired,
};

LinhaTabelaComplementar.defaultProps = {
  dadosDaLinha: {},
  setValueField: () => {},
};

export default LinhaTabelaComplementar;
