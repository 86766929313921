import { defaultGet, defaultPost } from '@4beefopag/4bee-fopag-core/dist/services';

import { alimentacaoRefeicao, historicoLancamento } from './mocks';

/**
 * @typedef PeriodoLancamento
 * @property {number} valorAlimentacao
 * @property {number} valorRefeicao
 * @property {number} dia_inicial_lanc_benef_ali_ref
 * @property {number} dia_final_lanc_benef_ali_ref
 * @property {number} ali_ref_dias_uteis
 * @property {number} ali_ref_valor_max_lanc
 * @property {number} idStatus
 */

export const getAlimentacaoRefeicao = async () =>
  defaultGet({}, '/beneficios/alimentacaorefeicao', {}, alimentacaoRefeicao);
export const buscarHistoricoLancamento = async () =>
  defaultGet({}, '/beneficios/alimentacaorefeicao/buscarHistorico', undefined, historicoLancamento);
export const salvarLancamento = async (model) => defaultPost(model, '/beneficios/alimentacaorefeicao/salvar');
