import { useEffect, useState } from 'react';
import moment from 'moment';

export default (cabecalho, acesso) => {
  const verificaValidacao = () => {
    if (cabecalho == null) {
      return {
        permiteFinalizar: false,
        permiteSalvar: false,
      };
    }

    const validacoes = {
      permiteFinalizar: true,
      permiteSalvar: true,
    };

    if (acesso.isAdmin()) {
      validacoes.permiteFinalizar = true;
      validacoes.permiteSalvar = true;
      return validacoes;
    }

    if (moment().isAfter(moment(cabecalho.dataLimite))) {
      // data limite passou
      validacoes.permiteFinalizar = false;
      validacoes.permiteSalvar = false;
    }

    return validacoes;
  };

  const [validacao, setValidacao] = useState(verificaValidacao());

  useEffect(() => {
    setValidacao(verificaValidacao());
  }, [cabecalho]);

  return validacao;
};
