import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { CustomText } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { Legenda } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { moneyFormat } from '@4beefopag/4bee-fopag-core/dist/util';

const Progress = styled.div`
  display: flex;
  margin-top: 10px;
  vertical-align: center;
`;

const ProgressBar = styled.div`
  flex: ${(props) => props.flex};
  min-width: 4px;
  background-color: ${(props) => (props.tipo === 'TR' ? '#12204a' : '#b00020')};
  height: 8px;
  align-self: center;
`;

function HistoricoLancamentoBeneficios({ historico }) {
  const [maiorValor, setMaiorValor] = useState(0);

  useEffect(() => {
    setMaiorValor(
      Math.max(...historico.map((h) => (h.valorAlimentacao > h.valorRefeicao ? h.valorAlimentacao : h.valorRefeicao)))
    );
  }, [historico]);

  const component = historico.map((item) => (
    <div
      key={item.id}
      style={{ marginTop: 15 }}
    >
      <CustomText fontSize="12px">{moment(item.competencia).format('MMM/YYYY')}</CustomText>
      <Progress>
        <ProgressBar
          flex={item.valorRefeicao / maiorValor}
          tipo="TR"
        />
        <CustomText
          fontSize="12px"
          strong
          marginleft="10px"
        >
          {moneyFormat(item.valorRefeicao)}
        </CustomText>
      </Progress>
      <Progress>
        <ProgressBar
          flex={item.valorAlimentacao / maiorValor}
          tipo="TA"
        />
        <CustomText
          fontSize="12px"
          strong
          marginleft="10px"
        >
          {moneyFormat(item.valorAlimentacao)}
        </CustomText>
      </Progress>
    </div>
  ));

  return (
    <>
      {component}
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Legenda
          color="#12204a"
          label="Vale Refeição"
        />
        <Legenda
          color="#b00020"
          label="Vale Alimentação"
        />
      </div>
    </>
  );
}

HistoricoLancamentoBeneficios.defaultProps = {
  historico: [],
};
HistoricoLancamentoBeneficios.propTypes = {
  historico: PropTypes.arrayOf(
    PropTypes.shape({
      valorAlimentacao: PropTypes.number.isRequired,
      valorRefeicao: PropTypes.number.isRequired,
    })
  ),
};

export default HistoricoLancamentoBeneficios;
