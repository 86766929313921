import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import { useHttpRequest } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import { buscar, confirmarLeitura } from '../../../services/notificacoes';

const botoes = {
  utilidades: (key) => (
    <Button
      type="link"
      size="small"
      onClick={() => {
        window.location.href = '/cesta-beneficios';
        notification.close(key);
      }}
    >
      Ver benefício
    </Button>
  ),
};

const openNotifications = (notificacoes) => {
  const readPromises = [];
  notificacoes.forEach((notificacao) => {
    const key = `open${Date.now()}`;
    notification.open({
      key,
      message: notificacao.titulo,
      description: notificacao.descricao,
      btn: botoes[notificacao.tipo](key),
      duration: null,
    });
    readPromises.push(confirmarLeitura(notificacao.id));
  });

  Promise.all(readPromises);
};

const Notificacoes = () => {
  const {
    state: { data: notificacoes, loading, error },
  } = useHttpRequest(buscar, [], []);

  useEffect(() => {
    if (!loading && !error && notificacoes.length) {
      openNotifications(notificacoes);
    }
  }, [notificacoes, loading, error]);

  return <></>;
};

export default Notificacoes;
