import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { http } from '@4beefopag/4bee-fopag-core/dist/services';
import { Alert, Button } from 'antd';

const ValidacaoLancamentos = ({ idRegulamento, vigenciaRegulamento, onValidando, onValidacaoFinalizada, onVoltar }) => {
  const [state, setState] = useState({ erro: undefined });

  useEffect(() => {
    setState({ ...state, erro: undefined });
    onValidando();

    if (idRegulamento) {
      (async () => {
        try {
          await http.get('regulamento/verificarLancamentos', {
            params: {
              id: idRegulamento,
              vigenciaRegulamento,
            },
          });

          onValidacaoFinalizada();
        } catch (e) {
          if (e.response && e.response.data && e.response.data.status === 400) {
            setState({ ...state, erro: e.response.data.message });
          } else {
            setState({
              ...state,
              erro: 'Algo de errado aconteceu, por favor tente novamente.',
            });
          }

          onValidacaoFinalizada({ erro: true });
        }
      })();
    } else {
      setTimeout(() => {
        onValidacaoFinalizada();
      }, 1000);
    }
  }, [idRegulamento]);

  return (
    <div>
      {state.erro && (
        <>
          <Alert
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'break-spaces',
              marginBottom: '8px',
            }}
            message="Regulamento não pode ser editado"
            showIcon
            description={state.erro}
            type="error"
          />

          <Button
            type="primary"
            style={{ marginRight: '8px' }}
            onClick={() =>
              onValidacaoFinalizada({
                visualizacao: true,
              })
            }
          >
            Abrir como somente leitura
          </Button>
          <Button onClick={() => onVoltar()}>Voltar</Button>
        </>
      )}
    </div>
  );
};

ValidacaoLancamentos.propTypes = {
  idRegulamento: propTypes.number,
  vigenciaRegulamento: propTypes.string,
  onValidando: propTypes.func.isRequired,
  onValidacaoFinalizada: propTypes.func.isRequired,
  onVoltar: propTypes.func.isRequired,
};

ValidacaoLancamentos.defaultProps = {
  idRegulamento: undefined,
  vigenciaRegulamento: undefined,
};

export default ValidacaoLancamentos;
