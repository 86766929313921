import { CustomText, Table, TableWrapper } from '@4beefopag/4bee-fopag-core/dist/atoms';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { eventos } from '../../../services/calculo-ticket';
import { colaboradorProps, regulamentoProps } from '../../atoms/Props';
import LinhaTabela from '../../molecules/LinhaTabela';
import TotalTabela from '../../molecules/TotalTabela';

const { colunas } = eventos;

const DefaultLabel = styled(CustomText)`
  font-size: 14px;
  color: var(--text-grey-6);
  height: 16px;
`;

const getEvento = (itens, { coluna }) => itens.eventos.find((e) => e.coluna === coluna).valor;

const TabelaBeneficios = ({ colaboradores, regulamentos, eventosTotalizadores, calcular, permiteEditar }) => (
  <>
    <TableWrapper>
      <Table
        widthInput="112px"
        background="#f6f7f9"
        borderBottomTh="1px solid #e8e8e8"
        borderBottomTd="1px solid #e8e8e8"
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            <th>CPF</th>
            <th>Nome</th>
            <th>
              <DefaultLabel>Dias Adicionais</DefaultLabel>
            </th>
            <th>
              <DefaultLabel>Dias de Desconto</DefaultLabel>
            </th>
            <th>
              <DefaultLabel>Refeição Dia</DefaultLabel>
            </th>
            {regulamentos.comp_dif_colab && <th>Composição ref.</th>}
            <th>
              <DefaultLabel>Valor Refeição</DefaultLabel>
            </th>
            {/* removido `regulamentos.solicita_va` por solicitação da nayara,
              não necessariamente o gestor precisa definir valor de TA para habilitar transferencia,
              vide bug:

              https://4beebwg.atlassian.net/browse/FP2-1724
              */}
            {regulamentos.trans_vr_para_va && (
              <th>
                <DefaultLabel>Transfere para Alimentação</DefaultLabel>
              </th>
            )}
            {regulamentos.comp_dif_colab && <th>Composição ali.</th>}
            <th>
              <DefaultLabel>Valor Alimentação</DefaultLabel>
            </th>
          </tr>
        </thead>
        <tbody>
          <>
            {colaboradores.map((c) => (
              <LinhaTabela
                key={c.colaborador.funcCodigo}
                colaborador={c.colaborador}
                regulamentos={regulamentos}
                calcular={calcular}
                permiteEditar={permiteEditar}
                diasAdicionais={getEvento(c, colunas.dias_adicionais)}
                diasDesconto={getEvento(c, colunas.dias_desconto)}
                refeicaoDia={getEvento(c, colunas.refeicao_dia)}
                transfereParaAlimentacao={getEvento(c, colunas.tranfere_para_alimentacao)}
                valorAlimentacao={getEvento(c, colunas.valor_alimentacao)}
                valorAlimentacaoForcado={getEvento(c, colunas.valor_alimentacao_forcado)}
                valorRefeicao={getEvento(c, colunas.valor_refeicao)}
                compDifColab={regulamentos.comp_dif_colab}
                transVrParaVa={regulamentos.trans_vr_para_va}
                vrDiferenteColab={regulamentos.vr_diferente_colab}
                funcCodigo={c.colaborador.funcCodigo}
                nome={c.colaborador.nome}
                descricaoSituacao={c.colaborador.descricaoSituacao}
                codigoSituacao={c.colaborador.codigoSituacao}
                cpfContratado={c.colaborador.cpfContratado}
                rescisaoEfetivada={c.colaborador.rescisaoEfetivada}
                rescisaoProgramada={c.colaborador.rescisaoProgramada}
                quantidadeDias={getEvento(c, colunas.quantidade_dias)}
                dataSituacao={c.colaborador.dataSituacao}
                periodoFeriasList={c.colaborador.periodoFeriasList}
                composicaoVa={getEvento(c, colunas.comp_valor_refeicao)}
                composicaoTa={getEvento(c, colunas.comp_valor_alimentacao)}
                valorRefeicaoColaborador={
                  c.alimentacaoRefeicao != null ? c.alimentacaoRefeicao.valorRefeicao : undefined
                }
                valorAlimentacaoColaborador={
                  c.alimentacaoRefeicao != null ? c.alimentacaoRefeicao.valorAlimentacao : undefined
                }
              />
            ))}

            <TotalTabela
              eventos={eventosTotalizadores}
              regulamentos={regulamentos}
            />
          </>
        </tbody>
      </Table>
    </TableWrapper>
  </>
);

TabelaBeneficios.propTypes = {
  colaboradores: PropTypes.arrayOf(colaboradorProps),
  regulamentos: regulamentoProps,
  eventosTotalizadores: PropTypes.arrayOf(
    PropTypes.shape({
      coluna: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
  calcular: PropTypes.func.isRequired,
  permiteEditar: PropTypes.bool,
};

TabelaBeneficios.defaultProps = {
  colaboradores: [],
  regulamentos: {},
  eventosTotalizadores: [],
  permiteEditar: true,
};

export default TabelaBeneficios;
