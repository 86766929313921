import React, { useState } from 'react';
import { Button, DatePicker, Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

const TextoModal = styled.p`
  font-size: 12px;
  color: #a3a4a5;
`;

const ModalStyled = styled(Modal)`
  .ant-modal-footer {
    border-top: unset;
  }
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    font-size: 16px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }
`;

const ModalReaberturaAdmin = ({ visible, onOk, onCancel, loading }) => {
  const [dataLimite, setDataLimite] = useState(
    moment.utc().add(7, 'days').hours(18).minutes(0).seconds(0).milliseconds(0)
  );

  return (
    <ModalStyled
      open={visible}
      title="Reabertura"
      onOk={onOk}
      onCancel={onCancel}
      closeable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk(dataLimite)}
          loading={loading}
        >
          Enviar
        </Button>,
        <Button
          key="back"
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>,
      ]}
    >
      <TextoModal>
        Para finalizar o pedido de reabertura, informe ao gestor a data e horário limite para finalizar o lançamento:
      </TextoModal>
      <DatePicker
        showTime
        placeholder="DD/MM/YYYY HH:mm"
        format="DD/MM/YYYY HH:mm"
        value={dataLimite}
        onChange={(data) => setDataLimite(data)}
      />
    </ModalStyled>
  );
};

ModalReaberturaAdmin.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ModalReaberturaAdmin.defaultProps = {
  visible: false,
};

export default ModalReaberturaAdmin;
