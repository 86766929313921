/* eslint-disable max-len,no-param-reassign */
import { CustomText, InputMoney } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { LabelSituacao } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { formataCpfCnpj, moneyFormat } from '@4beefopag/4bee-fopag-core/dist/util';
import { faPencil, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Input, Popover, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { eventos } from '../../../services/calculo-ticket';

const CustomInput = styled(Input)`
  border-color: var(--error) !important;
`;

const LinhaTabela = ({
  valorAlimentacao,
  valorAlimentacaoForcado,
  transfereParaAlimentacao,
  diasAdicionais,
  diasDesconto,
  refeicaoDia,
  valorRefeicao,
  transVrParaVa,
  vrDiferenteColab,
  compDifColab,
  // solicitaVa,
  funcCodigo,
  rescisaoProgramada,
  rescisaoEfetivada,
  cpfContratado,
  nome,
  descricaoSituacao,
  codigoSituacao,
  calcular,
  permiteEditar,
  quantidadeDias,
  periodoFeriasList,
  dataSituacao,
  composicaoVa,
  composicaoTa,
  valorRefeicaoColaborador,
  valorAlimentacaoColaborador,
}) => {
  const isEditando = () => valorAlimentacaoForcado !== null || !transVrParaVa;

  const [editandoVA, setEditandoVA] = useState(isEditando());
  const rescisaoInvalida = rescisaoProgramada === '1' || rescisaoEfetivada === '1';

  const [valorTransfere, setValorTransfere] = useState(0.0);

  useEffect(() => {
    setEditandoVA(isEditando());
  }, [valorAlimentacao]);

  useEffect(() => {
    setValorTransfere(transfereParaAlimentacao);
    setEditandoVA(isEditando());
  }, [transfereParaAlimentacao]);

  useEffect(() => {
    calcular(funcCodigo, {});
  }, [editandoVA]);

  const converterStringParaValor = (valor) => {
    if (valor === '') {
      return undefined;
    }
    return parseInt(valor, 10);
  };

  let renderDiasDesconto;
  if (diasDesconto > quantidadeDias + diasAdicionais) {
    renderDiasDesconto = (
      <td>
        <Popover
          content="A quantidade de dias de desconto não pode superar os dias trabalhados."
          title="Dias de Desconto"
        >
          <CustomInput
            type="number"
            disabled={!permiteEditar || rescisaoInvalida}
            min={0}
            value={diasDesconto}
            onChange={(e) =>
              calcular(funcCodigo, {
                [eventos.colunas.dias_desconto.coluna]: converterStringParaValor(e.target.value),
              })
            }
            style={{ width: '112px' }}
          />
        </Popover>
      </td>
    );
  } else {
    renderDiasDesconto = (
      <td>
        <Input
          type="number"
          disabled={!permiteEditar || rescisaoInvalida}
          min={0}
          value={diasDesconto}
          onChange={(e) =>
            calcular(funcCodigo, {
              [eventos.colunas.dias_desconto.coluna]: converterStringParaValor(e.target.value),
            })
          }
          style={{ width: '112px' }}
        />
      </td>
    );
  }

  return (
    <tr>
      <td>
        <CustomText
          fontSize="14px"
          color="var(--text-grey-8)"
          height="16px"
        >
          {formataCpfCnpj(cpfContratado)}
        </CustomText>
      </td>
      <td>
        <LabelSituacao
          nome={`${funcCodigo} - ${nome}`}
          descricaoSituacao={
            descricaoSituacao +
            periodoFeriasList
              .map((periodo) => ` Periodo inicial: ${periodo.gozoInicial} Periodo final: ${periodo.gozoFinal}`)
              .join('')
          }
          codigoSituacao={codigoSituacao}
          dataSituacao={dataSituacao}
        />
      </td>
      <td>
        <Input
          type="number"
          disabled={!permiteEditar || rescisaoInvalida}
          min={0}
          value={diasAdicionais}
          onChange={(e) =>
            calcular(funcCodigo, {
              [eventos.colunas.dias_adicionais.coluna]: converterStringParaValor(e.target.value),
            })
          }
          style={{ width: '112px' }}
        />
      </td>
      {renderDiasDesconto}
      {vrDiferenteColab ? (
        <td>
          <InputMoney
            disabled={!permiteEditar || rescisaoInvalida}
            min={0}
            value={refeicaoDia}
            getValue={(value) =>
              calcular(funcCodigo, {
                [eventos.colunas.refeicao_dia.coluna]: value,
              })
            }
            style={{ width: '112px' }}
          />
        </td>
      ) : (
        <td>
          <CustomText
            ellipsis
            strong
          >
            {moneyFormat(refeicaoDia)}
          </CustomText>
        </td>
      )}
      {compDifColab && (
        <td style={{ textAlign: 'center' }}>
          <Checkbox
            disabled={!permiteEditar || rescisaoInvalida}
            checked={composicaoVa}
            onClick={(e) => {
              calcular(funcCodigo, {
                [eventos.colunas.comp_valor_refeicao.coluna]: e.target.checked ? 1 : 0,
              });
            }}
          />
        </td>
      )}
      <td>
        {valorRefeicaoColaborador && valorRefeicao !== valorRefeicaoColaborador ? (
          <Tooltip title={`Editado pelo colaborador\n Valor R$ ${valorRefeicaoColaborador}`}>
            <CustomText
              ellipsis
              strong
            >
              {moneyFormat(valorRefeicao)}
            </CustomText>
          </Tooltip>
        ) : (
          <CustomText
            ellipsis
            strong
          >
            {moneyFormat(valorRefeicao)}
          </CustomText>
        )}
      </td>
      {/* removido `solicitaVa` por solicitação da nayara,
        não necessariamente o gestor precisa definir valor de TA para habilitar transferencia,
        vide bug:

        https://4beebwg.atlassian.net/browse/FP2-1724
        */}
      {transVrParaVa && (
        <td>
          {editandoVA ? (
            <CustomText
              ellipsis
              strong
            >
              {moneyFormat(transfereParaAlimentacao)}
            </CustomText>
          ) : (
            <InputMoney
              disabled={!permiteEditar}
              min={0}
              value={valorTransfere}
              getValue={setValorTransfere}
              onBlur={() =>
                calcular(funcCodigo, {
                  [eventos.colunas.valor_alimentacao.coluna]: null,
                  [eventos.colunas.valor_alimentacao_forcado.coluna]: null,
                  [eventos.colunas.tranfere_para_alimentacao.coluna]: valorTransfere,
                })
              }
              max={valorRefeicao}
              style={{ width: '144px' }}
            />
          )}
        </td>
      )}
      {compDifColab && (
        <td style={{ textAlign: 'center' }}>
          <Checkbox
            disabled={!permiteEditar || rescisaoInvalida}
            checked={composicaoTa}
            onClick={(e) => {
              calcular(funcCodigo, {
                [eventos.colunas.comp_valor_alimentacao.coluna]: e.target.checked ? 1 : 0,
              });
            }}
          />
        </td>
      )}
      <td>
        {editandoVA ? (
          <>
            <InputMoney
              disabled={!permiteEditar}
              min={0}
              value={valorAlimentacao}
              getValue={(value) =>
                calcular(funcCodigo, {
                  [eventos.colunas.valor_alimentacao.coluna]: value,
                })
              }
              style={{ width: '112px' }}
            />
            {transVrParaVa && permiteEditar && (
              <Button
                type="link"
                size="small"
                style={{ display: 'inline', minWidth: 'auto' }}
                onClick={() => {
                  calcular(funcCodigo, {
                    [eventos.colunas.valor_alimentacao.coluna]: null,
                    [eventos.colunas.valor_alimentacao_forcado.coluna]: null,
                    // [eventos.colunas.tranfere_para_alimentacao.coluna]: 0,
                  });
                  setEditandoVA(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </>
        ) : (
          <>
            <CustomText
              ellipsis
              strong
            >
              {valorAlimentacaoColaborador && valorAlimentacao !== valorAlimentacaoColaborador ? (
                <Tooltip title={`Editado pelo colaborador\n Valor R$ ${valorAlimentacaoColaborador}`}>
                  <span>{moneyFormat(valorAlimentacao)}</span>
                </Tooltip>
              ) : (
                <span>{moneyFormat(valorAlimentacao)}</span>
              )}
              {permiteEditar && (
                <Button
                  type="link"
                  size="small"
                  style={{ display: 'inline', minWidth: 'auto' }}
                  onClick={() => {
                    setEditandoVA(true);

                    calcular(funcCodigo, {
                      [eventos.colunas.valor_alimentacao.coluna]: null,
                      [eventos.colunas.valor_alimentacao_forcado.coluna]: 0,
                      // [eventos.colunas.tranfere_para_alimentacao.coluna]: 0,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Button>
              )}
            </CustomText>
          </>
        )}
      </td>
      {/* <td> */}
      {/*  <pre> */}
      {/*    {JSON.stringify({ */}
      {/*      editandoVA, */}
      {/*      transVrParaVa, */}
      {/*      permiteEditar, */}
      {/*      solicitaVa, */}
      {/*    })} */}
      {/*  </pre> */}
      {/* </td> */}
    </tr>
  );
};

LinhaTabela.propTypes = {
  valorAlimentacao: PropTypes.number,
  valorAlimentacaoForcado: PropTypes.number,
  transfereParaAlimentacao: PropTypes.number,
  diasAdicionais: PropTypes.number,
  diasDesconto: PropTypes.number,
  refeicaoDia: PropTypes.number,
  valorRefeicao: PropTypes.number,
  funcCodigo: PropTypes.number.isRequired,
  rescisaoProgramada: PropTypes.string.isRequired,
  rescisaoEfetivada: PropTypes.string.isRequired,
  cpfContratado: PropTypes.string.isRequired,
  nome: PropTypes.string.isRequired,
  descricaoSituacao: PropTypes.string.isRequired,
  codigoSituacao: PropTypes.number.isRequired,
  calcular: PropTypes.func.isRequired,
  permiteEditar: PropTypes.bool,
  transVrParaVa: PropTypes.bool.isRequired,
  vrDiferenteColab: PropTypes.bool.isRequired,
  compDifColab: PropTypes.bool.isRequired,
  // solicitaVa: PropTypes.bool.isRequired,
  quantidadeDias: PropTypes.number,
  dataSituacao: PropTypes.string,
  periodoFeriasList: PropTypes.arrayOf(
    PropTypes.shape({
      gozoInicial: PropTypes.string.isRequired,
      gozoFinal: PropTypes.string.isRequired,
    })
  ),
  composicaoVa: PropTypes.number,
  composicaoTa: PropTypes.number,
  valorRefeicaoColaborador: PropTypes.number,
  valorAlimentacaoColaborador: PropTypes.number,
};

LinhaTabela.defaultProps = {
  valorAlimentacao: undefined,
  valorAlimentacaoForcado: undefined,
  transfereParaAlimentacao: undefined,
  diasAdicionais: undefined,
  diasDesconto: undefined,
  refeicaoDia: undefined,
  valorRefeicao: undefined,
  permiteEditar: true,
  quantidadeDias: undefined,
  periodoFeriasList: [],
  dataSituacao: undefined,
  composicaoVa: 0,
  composicaoTa: 0,
  valorRefeicaoColaborador: undefined,
  valorAlimentacaoColaborador: undefined,
};

export default memo(LinhaTabela);
