import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default {
  /**
   * Inicializa o sentry
   * @param {*} dns URL do DNS
   * @param {*} env NODE_ENV
   */
  initialize(dns, env) {
    Sentry.init({
      dsn: dns,
      integrations: [new Integrations.BrowserTracing()],
      environment: env,
      beforeSend: (event) => {
        if (['production'].includes(env)) {
          return event;
        }
        return null;
      },
      tracesSampleRate: 1.0,
    });
  },
};
