import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Collapse, Modal, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUtensils } from '@fortawesome/free-solid-svg-icons';

import { useHttpRequest } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import { Card, CustomText, InputMoney, Label } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { moneyFormat } from '@4beefopag/4bee-fopag-core/dist/util';

// import { moneyFormat } from '@4beefopag/4bee-fopag-core/dist/util';
// import {
//  Calculo as CalcularEstrategicoV3
// } from '@4beefopag/4bee-fopag-core/dist/services/calculo-apontamento';
import { http } from '@4beefopag/4bee-fopag-core/dist/services';
import { mostrarErro } from '@4beefopag/4bee-fopag-core/dist/molecules.es';
import { buscarHistoricoLancamento, getAlimentacaoRefeicao } from '../../../services/solicitacaoTicket';
import HistoricoLancamentoBeneficios from '../../molecules/HistoricoLancamentoBeneficios';
import BeneficiosPage from '../../molecules/BeneficiosPage';

// import buscarColaboradorApontamento from '../../../services/colaboradorApontamento';
// import { calcularFolha } from '../../../services/calculoFolha';
// import useAlertas from './useAlertas';

const { Panel } = Collapse;

function SolicitacaoTicket() {
  const {
    state: {
      /** @type {PeriodoLancamento} */
      data: periodoLancamento,
      loading,
    },
    /** @param {PeriodoLancamento} periodoLancamento */
    setData: setPeriodoLancamento,
  } = useHttpRequest(() => getAlimentacaoRefeicao(), {}, []);

  const {
    state: { data: historicoLancamento },
  } = useHttpRequest(() => buscarHistoricoLancamento(), [], []);

  // const {
  //   state: {
  //     data: colaborador,
  //   },
  // } = useHttpRequest(() => buscarColaboradorApontamento({ idContratado: 1 }), {
  //   colaborador: {}, configuracoes: {}, regras: [], eventos: [], eventosFicha: [],
  // }, []);

  // useHttpRequest(() => calcularFolha({ idContratado: colaborador.colaborador.funcCodigo }),
  //   {}, [colaborador.colaborador.funcCodigo]);

  // const [calculo, setCalculo] = useState(new CalcularEstrategicoV3(
  //   colaborador.colaborador,
  //   colaborador.configuracao,
  //   colaborador.regras,
  //   colaborador.eventos,
  //   colaborador.eventosFicha,
  // ));

  // const [saldo] = useState(
  //   periodoLancamento.ali_ref_valor_max_lanc - periodoLancamento.valorAlimentacao,
  // );

  // const { alerta, editadoPeloGestor } = useAlertas(
  // loading, saldo, periodoLancamento, undefined,
  // periodoLancamento.alimentacaoRefeicao.idContratado,
  // );

  // useEffect(() => {
  //   const aux = new CalcularEstrategicoV3(
  //     { ...colaborador.colaborador },
  //     colaborador.configuracao,
  //     colaborador.regras,
  //     colaborador.eventos,
  //     colaborador.eventosFicha,
  //   );
  //   aux.calcular();
  //   setCalculo(aux);
  // }, [colaborador]);

  // useEffect(() => {
  //   setSaldo(periodoLancamento.ali_ref_valor_max_lanc - periodoLancamento.valorAlimentacao);
  // }, [periodoLancamento.ali_ref_valor_max_lanc, periodoLancamento.valorAlimentacao]);

  const [valorOriginalTransfere, setValorOriginalTransfere] = useState({
    valor: 0,
    editado: false,
  });

  const calcularTransfere = () => {
    const periodoLancamentoCop = { ...periodoLancamento };
    let resultadoRefeicao = 0;
    let resultadoAlimentacao = 0;

    if (!valorOriginalTransfere.editado) {
      setValorOriginalTransfere({
        ...valorOriginalTransfere,
        editado: true,
        valor: periodoLancamento.alimentacaoRefeicao.transfereAlimentacao,
      });
    }

    if (periodoLancamento.alimentacaoRefeicao.transfereAlimentacao === 0) {
      setValorOriginalTransfere({
        ...valorOriginalTransfere,
        editado: false,
        valor: 0,
      });
      resultadoRefeicao = periodoLancamento.valorOriginalRefeicao;
      resultadoAlimentacao = periodoLancamento.valorOriginalAlimentacao;
    } else if (valorOriginalTransfere.valor <= periodoLancamento.alimentacaoRefeicao.transfereAlimentacao) {
      resultadoRefeicao =
        periodoLancamentoCop.alimentacaoRefeicao.valorRefeicao -
        periodoLancamento.alimentacaoRefeicao.transfereAlimentacao;
      resultadoAlimentacao =
        periodoLancamentoCop.alimentacaoRefeicao.valorAlimentacao +
        periodoLancamento.alimentacaoRefeicao.transfereAlimentacao;
    } else {
      resultadoRefeicao =
        periodoLancamentoCop.alimentacaoRefeicao.valorRefeicao +
        periodoLancamento.alimentacaoRefeicao.transfereAlimentacao;
      resultadoAlimentacao =
        periodoLancamentoCop.alimentacaoRefeicao.valorAlimentacao -
        periodoLancamento.alimentacaoRefeicao.transfereAlimentacao;
    }
    setPeriodoLancamento({
      ...periodoLancamento,
      alimentacaoRefeicao: {
        ...periodoLancamento.alimentacaoRefeicao,
        valorRefeicao: resultadoRefeicao,
        valorAlimentacao: resultadoAlimentacao,
      },
    });
  };

  const enviarSolicitacao = async () => {
    const modelEnvio = {
      valorAlimentacao: periodoLancamento.alimentacaoRefeicao.valorAlimentacao,
      valorRefeicao: periodoLancamento.alimentacaoRefeicao.valorRefeicao || 0,
      transfereAlimentacao: periodoLancamento.alimentacaoRefeicao.transfereAlimentacao || 0,
    };

    try {
      await http.post('/beneficios/alimentacaorefeicao/salvar', modelEnvio);

      Modal.success({
        title: 'Sucesso',
        content: 'Solicitado com sucesso',
        centered: true,
      });
    } catch (e) {
      mostrarErro(e);
    }
  };

  return (
    <BeneficiosPage titulo="Solicitar Alimentação e Refeição">
      {periodoLancamento.regulamento && (
        <>
          <Row
            gutter={26}
            type="flex"
            justify="center"
          >
            <Col style={{ borderRight: '2px solid #c1c2c4' }}>
              <Card style={{ padding: 8 }}>
                <CustomText
                  fontSize="12px"
                  color="#656668"
                >
                  Período para Solicitar
                </CustomText>
                <CustomText
                  fontSize="18px"
                  color="#262626"
                  strong
                >
                  Dia {periodoLancamento.dia_inicio_lancamento} ao dia{' '}
                  {periodoLancamento.regulamento.dia_limite_lancamento}
                </CustomText>
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: 8 }}>
                <CustomText
                  fontSize="12px"
                  color="#656668"
                >
                  Quantidade de dias
                </CustomText>
                <CustomText
                  fontSize="18px"
                  color="#262626"
                  strong
                >
                  {periodoLancamento.diasUteis}
                </CustomText>
              </Card>
            </Col>
            {/* <Col style={{ borderLeft: '2px solid #c1c2c4' }}>
              <Card style={{ padding: 8 }}>
                <CustomText fontSize="12px" color="#656668">
                  Valor Máximo para solicitar
                </CustomText>
                <CustomText fontSize="18px" color="#262626" strong>
                  {moneyFormat(periodoLancamento.ali_ref_valor_max_lanc)}
                </CustomText>
              </Card>
            </Col>
            {!editadoPeloGestor && (
              <Col style={{ borderLeft: '2px solid #c1c2c4' }}>
                <Card style={{ padding: 8, minWidth: '150px' }}>
                  <CustomText fontSize="12px" color="#656668">
                    Saldo
                  </CustomText>
                  <CustomText fontSize="18px" color="#262626" strong>
                    {moneyFormat(saldo)}
                  </CustomText>
                </Card>
              </Col>
            )} */}
          </Row>
          <Label
            label={
              <CustomText
                fontSize="16px"
                color="var(--primary)"
                strong
              >
                Valor Mensal
              </CustomText>
            }
          >
            <Row
              gutter={[64, 16]}
              style={{ marginTop: 16 }}
            >
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={6}
                xxl={6}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Label label="Refeição">
                      {periodoLancamento.diferencaValorRefeicao ? (
                        <Tooltip title="Editado pelo gestor">
                          <CustomText
                            ellipsis
                            strong
                          >
                            {moneyFormat(periodoLancamento.alimentacaoRefeicao.valorRefeicao)}
                          </CustomText>
                        </Tooltip>
                      ) : (
                        <CustomText
                          ellipsis
                          strong
                        >
                          {moneyFormat(periodoLancamento.alimentacaoRefeicao.valorRefeicao)}
                        </CustomText>
                      )}
                    </Label>
                  </Col>
                  {periodoLancamento.regulamento.trans_vr_para_va && (
                    <Col span={24}>
                      <Label label="Transfere para Alimentação">
                        <InputMoney
                          min={0}
                          value={periodoLancamento.alimentacaoRefeicao.transfereAlimentacao}
                          getValue={(value) => {
                            setPeriodoLancamento({
                              ...periodoLancamento,
                              alimentacaoRefeicao: {
                                ...periodoLancamento.alimentacaoRefeicao,
                                transfereAlimentacao: value,
                              },
                            });
                          }}
                          onBlur={calcularTransfere}
                          max={periodoLancamento.alimentacaoRefeicao.valorRefeicao}
                        />
                      </Label>
                    </Col>
                  )}
                  <Col span={24}>
                    {periodoLancamento.diferencaValorAlimentacao ? (
                      <Tooltip title="Editado pelo gestor">
                        <CustomText>Valor Alimentação</CustomText>
                        <InputMoney
                          prefix="R$"
                          value={periodoLancamento.alimentacaoRefeicao.valorAlimentacao}
                          getValue={(value) => {
                            setPeriodoLancamento({
                              ...periodoLancamento,
                              alimentacaoRefeicao: {
                                ...periodoLancamento.alimentacaoRefeicao,
                                valorAlimentacao: value,
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Label label="Valor Alimentação">
                        <InputMoney
                          prefix="R$"
                          value={periodoLancamento.alimentacaoRefeicao.valorAlimentacao}
                          getValue={(value) => {
                            setPeriodoLancamento({
                              ...periodoLancamento,
                              alimentacaoRefeicao: {
                                ...periodoLancamento.alimentacaoRefeicao,
                                valorAlimentacao: value,
                              },
                            });
                          }}
                        />
                      </Label>
                    )}
                  </Col>
                  {/* <Col span={24}>
                    {alerta && alerta.type && (
                      <Alert
                        message={alerta.message}
                        description={alerta.description}
                        type={alerta.type}
                      />
                    )}
                  </Col> */}
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 14, offset: 10 }}
                    lg={{ span: 14, offset: 10 }}
                    xl={{ span: 12, offset: 12 }}
                    xxl={{ span: 12, offset: 12 }}
                  >
                    {periodoLancamento.diferencaValorRefeicao ? (
                      <Tooltip
                        title="Editado pelo gestor"
                        placement="bottom"
                      >
                        <Button
                          type="primary"
                          disabled
                        >
                          Solicitar
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        block
                        type="primary"
                        loading={loading}
                        onClick={() => enviarSolicitacao()}
                      >
                        Solicitar
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={16}
                lg={16}
                xl={18}
                xxl={18}
              >
                <Row gutter={[28, 28]}>
                  <Col
                    span={24}
                    style={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridColumnGap: 24,
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: '#12204a' }}
                      shape="circle"
                      size={40}
                      icon={<FontAwesomeIcon icon={faShoppingCart} />}
                    />
                    <Label
                      label={
                        <CustomText
                          fontSize="14px"
                          color="#12204a"
                          strong
                        >
                          Alimentação
                        </CustomText>
                      }
                    >
                      <CustomText
                        fontSize="14px"
                        color="#8c8c8c"
                        lineHeight={1.71}
                      >
                        O cartão alimentação serve para comprar gêneros alimentícios (processados ou in natura, como
                        frutas e verduras) em supermercados, quitandas, mercearias, hortifrútis, sacolões e outros
                        estabelecimentos que vendem esse tipo de mercadoria.
                      </CustomText>
                    </Label>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridColumnGap: 24,
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: '#12204a' }}
                      shape="circle"
                      size={40}
                      icon={<FontAwesomeIcon icon={faUtensils} />}
                    />
                    <Label
                      label={
                        <CustomText
                          fontSize="14px"
                          color="#12204a"
                          strong
                        >
                          Refeição
                        </CustomText>
                      }
                    >
                      <CustomText
                        fontSize="14px"
                        color="#8c8c8c"
                        lineHeight={1.71}
                      >
                        A refeição serve para comprar refeições prontas durante o seu horário de trabalho. É o cartão
                        que você vai levar para se alimentar no almoço ou, dependendo do horário de sua jornada de
                        trabalho, na hora do jantar.
                      </CustomText>
                    </Label>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={16}
                    xl={14}
                    xxl={14}
                  >
                    <Collapse
                      defaultActiveKey={['historico']}
                      expandIcon={({ isActive }) => (
                        <RightOutlined
                          style={{ color: 'var(--primary)' }}
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      bordered={false}
                      accordion={false}
                    >
                      <Panel
                        style={{ border: 0 }}
                        key="historico"
                        isActive
                        header={
                          <CustomText
                            fontSize="16px"
                            color="var(--primary)"
                            strong
                          >
                            Histórico
                          </CustomText>
                        }
                      >
                        <HistoricoLancamentoBeneficios historico={historicoLancamento} />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Label>
        </>
      )}
      <Row gutter={[16, 16]} />
    </BeneficiosPage>
  );
}

export default SolicitacaoTicket;
