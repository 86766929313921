import React, { useCallback, useEffect, useState } from 'react';
import { useHttpRequest } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import { Col, Row, Steps, Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { buscarRegulamentoPorId, buscarRegulamentos } from '../../../services/regulamentosTicket';
import RegulamentosCadastrados from './RegulamentosCadastrados';
import ParametrosTela from './ParametrosTela';
import ValidacaoLancamentos from './ValidacaoLancamentos';
import FormularioTela from './FormularioTela';
import BeneficiosPage from '../../molecules/BeneficiosPage';

const { Step } = Steps;

function RegulamentoTicket({ match }) {
  const [regulamentoExistente, setRegulamentoExistente] = useState({
    id: undefined,
    vigencia: moment(),
  });

  const [loadingRegulamentoExistente, setLoadingRegulamentoExistente] = useState(false);

  const [parametros, setParametros] = useState({
    empresa: undefined,
    local: undefined,
    folha: undefined,
    vigencia: moment(),
  });

  const buscarRegulamentoPorIdParam = useCallback(async () => {
    const regulamento = await buscarRegulamentoPorId(match.params.id);

    regulamento.vigencia = moment(`${regulamento.ano_vigencia}-${regulamento.mes_vigencia}-01`, 'YYYY-MM-DD');

    return regulamento;
  }, []);

  const {
    state: { data: regulamentos, loading: loadingRegulamentos },
    request: reloadRegulamentos,
  } = useHttpRequest(
    () =>
      buscarRegulamentos({
        idEmpresa: parametros.empresa,
        idLocal: parametros.local,
        idFolha: parametros.folha,
      }),
    [],
    [parametros.empresa, parametros.local, parametros.folha]
  );

  const [etapa, setEtapa] = useState(0);
  const [validando, setValidando] = useState({
    loading: false,
    erro: false,
    visualizacao: false,
  });

  const proximaEtapa = () => {
    setEtapa(etapa + 1);
  };

  const etapas = {
    PARAMETROS: 0,
    VALIDACAO_LANCAMENTOS: 1,
    FORMULARIO: 2,
  };

  useEffect(() => {
    if (match.params.id) {
      setEtapa(etapas.PARAMETROS);
      setLoadingRegulamentoExistente(true);

      (async () => {
        const regulamento = await buscarRegulamentoPorIdParam();
        setParametros({
          empresa: regulamento.id_empresa,
          local: regulamento.id_local,
          folha: regulamento.id_folha,
          vigencia: regulamento.vigencia,
        });
        setRegulamentoExistente(regulamento);
        setLoadingRegulamentoExistente(false);
        setEtapa(etapas.VALIDACAO_LANCAMENTOS);
      })();
    }
  }, [match.params.id]);

  return (
    <>
      <Row>
        <Col span={16}>
          <BeneficiosPage titulo="Regulamento Benefícios">
            <Steps
              current={etapa}
              style={{ marginBottom: '40px' }}
            >
              <Step
                title="Parâmetros"
                description="Filtros do regulamento"
                icon={loadingRegulamentoExistente ? <Spin /> : undefined}
              />
              <Step
                title="Validação"
                description="Existem lançamentos?"
                status={validando.erro ? 'error' : undefined}
                icon={validando.loading ? <Spin /> : undefined}
              />
              <Step
                title="Formulário"
                description="Dados do regulamento"
              />
            </Steps>

            {etapa === etapas.PARAMETROS && !loadingRegulamentoExistente && (
              <ParametrosTela
                empresa={parametros.empresa}
                local={parametros.local}
                folha={parametros.folha}
                vigencia={parametros.vigencia}
                proximaEtapa={() => {
                  // limpa regulamento sendo editado
                  setRegulamentoExistente({
                    id_empresa: regulamentoExistente.id_empresa,
                    id_local: regulamentoExistente.id_local,
                    id_folha: regulamentoExistente.id_folha,
                    vigencia: regulamentoExistente.vigencia,
                  });
                  proximaEtapa();
                }}
                onChange={(parametrosTela) => {
                  setParametros({ ...parametros, ...parametrosTela });
                  setRegulamentoExistente({
                    ...regulamentoExistente,
                    id_empresa: parametrosTela.empresa,
                    id_local: parametrosTela.local,
                    id_folha: parametrosTela.folha,
                    vigencia: parametrosTela.vigencia,
                  });
                }}
              />
            )}

            {etapa === etapas.VALIDACAO_LANCAMENTOS && (
              <ValidacaoLancamentos
                idRegulamento={regulamentoExistente.id}
                vigenciaRegulamento={regulamentoExistente.vigencia.format('DD/MM/YYYY')}
                onValidando={() => setValidando({ ...validando, loading: true })}
                onValidacaoFinalizada={(validacao) => {
                  if (validacao && validacao.erro) {
                    setValidando({
                      ...validando,
                      loading: false,
                      erro: validacao.erro,
                    });
                  } else {
                    setValidando({
                      ...validando,
                      loading: false,
                      visualizacao: validacao ? validacao.visualizacao : false,
                      erro: undefined,
                    });
                    proximaEtapa();
                  }
                }}
                onVoltar={() => {
                  setValidando({ ...validando, loading: false, erro: false });
                  setEtapa(etapas.PARAMETROS);
                }}
              />
            )}

            {etapa === etapas.FORMULARIO && (
              <FormularioTela
                onVoltar={() => {
                  setValidando({ ...validando, loading: false, erro: false });
                  setEtapa(etapas.PARAMETROS);
                }}
                onSalvar={() => {
                  setEtapa(etapas.PARAMETROS);
                  reloadRegulamentos();
                }}
                onDesabilitar={() => {
                  setEtapa(etapas.PARAMETROS);
                  reloadRegulamentos();
                }}
                regulamentoExistente={regulamentoExistente}
                lancamentoFinalizado={validando.visualizacao}
              />
            )}
          </BeneficiosPage>
        </Col>

        <Col
          span={6}
          style={{
            padding: '40px',
            backgroundColor: 'white',
            position: 'fixed',
            top: '0px',
            right: '10px',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <RegulamentosCadastrados
            locais={[]}
            folhas={[]}
            onEditarRegulamento={(regulamento) => {
              const copiaRegulamento = { ...regulamento };
              copiaRegulamento.vigencia = moment(
                `${copiaRegulamento.ano_vigencia}-${copiaRegulamento.mes_vigencia}-01`
              );

              setRegulamentoExistente(copiaRegulamento);
              setEtapa(etapas.VALIDACAO_LANCAMENTOS);

              setValidando({
                ...validando,
                loading: true,
                erro: undefined,
              });

              // setFieldsData(copiaRegulamento);
              // verificaLancamento(copiaRegulamento);
            }}
            regulamentos={regulamentos}
            loadingRegulamentos={loadingRegulamentos}
          />
        </Col>
      </Row>
    </>
  );
}

RegulamentoTicket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

RegulamentoTicket.defaultProps = {
  match: {
    params: {
      id: undefined,
    },
  },
};

export default RegulamentoTicket;
