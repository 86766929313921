import React from 'react';
import ReactDOM from 'react-dom/client';
import GlobalStyle from './components/_settings/GlobalStyle';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Notificacoes from './components/atoms/Notificacoes';
import sentry from './services/util/sentry';

sentry.initialize(process.env.REACT_APP_SENTRY_DNS, process.env.NODE_ENV);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <GlobalStyle />
    <App />
    <Notificacoes />
  </>
);

serviceWorker.unregister();
