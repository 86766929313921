/** @type PeriodoLancamento */
export const alimentacaoRefeicao = {
  valorAlimentacao: 200,
  idStatus: 1,
  valorRefeicao: 150,
  dia_inicial_lanc_benef_ali_ref: 1,
  dia_final_lanc_benef_ali_ref: 10,
  ali_ref_dias_uteis: 20,
  ali_ref_valor_max_lanc: 300,
};

/** @type PeriodoLancamento[] */
export const historicoLancamento = [
  { valorAlimentacao: 190, valorRefeicao: 120 },
  { valorAlimentacao: 180, valorRefeicao: 160 },
  { valorAlimentacao: 190, valorRefeicao: 170 },
  { valorAlimentacao: 210, valorRefeicao: 140 },
];
