import { Button, Checkbox, Col, Input, InputNumber, Modal, Row } from 'antd';
import { CustomText, CustomLabel6, CustomLabel8, Label, InputMoney } from '@4beefopag/4bee-fopag-core/dist/atoms';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import propTypes from 'prop-types';
import moment from 'moment';
import { http, defaultGet } from '@4beefopag/4bee-fopag-core/dist/services';
import { TagsEmail } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { buscarRegulamento } from '../../../services/regulamentosTicket';

const CustomInputNumber = styled(InputNumber)`
  width: unset !important;
`;

const ErroMultiplasLinhas = styled.pre`
  word-break: break-word;
`;

const FormularioTela = ({ onVoltar, onSalvar, onDesabilitar, lancamentoFinalizado, regulamentoExistente }) => {
  const acesso = useContext(AuthContext);

  const initalFieldsData = {
    id: 0,
    nome_regulamento: '',
    dia_atualizacao_competencia: 1,
    solicita_vr: false,
    solicita_vr_valor: 0,
    solicita_va: false,
    solicita_va_valor: 0,
    considera_dias_fixos: false,
    qtd_dias_fixos: 0,
    proporcionaliza_ferias: false,
    proporcionaliza_afastamento: false,
    proporcionaliza_rescisao: false,
    vr_diferente_colab: false,
    trans_vr_para_va: false,
    comp_dif_colab: false,
    considera_prog_ferias: false,
    considera_prog_afastamento: false,
    email_alerta: undefined,
    vigencia: undefined,
    dia_limite_lancamento: 1,
    email_alerta_diretores: undefined,
    alerta_pedido_sup_inf: false,
    alerta_pedido_sup_inf_valor: 0,
  };

  const [fieldsData, setFieldsData] = useState(() => {
    if (regulamentoExistente) {
      return { ...initalFieldsData, ...regulamentoExistente };
    }

    return {
      ...initalFieldsData,
    };
  });

  const [loading, setLoading] = useState({ salvar: false });

  const validaRegulamento = (regulamento) => {
    let errosCampos = '';

    if (regulamento.nome_regulamento === '') {
      errosCampos += 'Preencha o campo Nome Regulamento';
    }

    if (regulamento.considera_dias_fixos && regulamento.qtd_dias_fixos === 0) {
      errosCampos += 'O campo "Considera dias fixos ?" está marcado por favor preencha o valor padrão';
    }

    return errosCampos;
  };

  const mostrarModalSucesso = (mensagem, mensagemVigorRegulamento, vigor) => {
    Modal.success({
      centered: true,
      width: 560,
      title: (
        <CustomText
          color="var(--text-grey-8)"
          fontSize={16}
        >
          {mensagem}
        </CustomText>
      ),
      content: (
        <p>
          {mensagemVigorRegulamento} {moment(vigor).utc().format('MM/YYYY')}.
        </p>
      ),
      okButtonProps: {
        ghost: true,
        style: {
          borderColor: '#c1c2c4',
        },
      },
    });
  };

  const validarErroRegulamento = (e) => {
    if (e.response && e.response.data && e.response.data.status === 400) {
      Modal.error({
        title: 'Ops...',
        content: <ErroMultiplasLinhas>{e.response.data.message}</ErroMultiplasLinhas>,
      });
    } else {
      Modal.error({
        title: 'Ops...',
        content: 'Algo de errado aconteceu, por favor tente novamente.',
      });
    }
  };

  const msgRegulamentoDesabilitado = async () => {
    const { vigencia } = fieldsData;

    const proximoRegulamento = await buscarRegulamento(
      fieldsData.id_empresa,
      fieldsData.id_local,
      fieldsData.id_folha,
      vigencia.format('DD/MM/YYYY')
    );

    let retornoTexto = {
      title: 'Regulamento Desabilitado',
      content: `${'Caso não seja criado outro regulamento para subtituir o desabilitado, entra em vigor o regulamento:'} ${
        proximoRegulamento.nome_regulamento
      } a partir da competência ${moment(proximoRegulamento.vigencia).format('MM/YYYY')}.`,
    };

    if (proximoRegulamento) {
      retornoTexto = {
        title: 'Regulamento foi desabilitado com sucesso',
        content: `Você desabilitou o regulamento com a vigência ${vigencia.format(
          'MM/YYYY'
        )}. Para os próximos lançamentos, o último regulamento cadastrado será utilizado`,
      };
    }

    return retornoTexto;
  };

  const desabilitarRegulamentos = async () => {
    setLoading({ ...loading, desabilitar: true });

    const cloneFieldsData = { ...fieldsData };
    cloneFieldsData.mes_vigencia = moment(cloneFieldsData.vigencia).format('MM');
    cloneFieldsData.ano_vigencia = moment(cloneFieldsData.vigencia).format('YYYY');
    try {
      await http.post('/regulamento/delete', cloneFieldsData);
      const textoModal = await msgRegulamentoDesabilitado();
      Modal.success({
        centered: true,
        width: 560,
        title: (
          <CustomText
            color="var(--text-grey-8)"
            fontSize={16}
          >
            {textoModal.title}
          </CustomText>
        ),
        content: textoModal.content,
        okButtonProps: {
          ghost: true,
          style: {
            borderColor: '#c1c2c4',
          },
        },
      });
      setLoading({ ...loading, desabilitar: false });
      onDesabilitar();
      setFieldsData({ ...fieldsData, id: 0 });
    } catch (e) {
      setLoading({ ...loading, desabilitar: false });
      validarErroRegulamento(e);
    }
  };

  const sendRegulamentos = async () => {
    setLoading({ ...loading, salvar: true });

    if (validaRegulamento(fieldsData) === '') {
      const cloneFieldsData = { ...fieldsData };
      cloneFieldsData.mes_vigencia = moment(cloneFieldsData.vigencia).format('MM');
      cloneFieldsData.ano_vigencia = moment(cloneFieldsData.vigencia).format('YYYY');
      try {
        await http.post('/regulamento/salvar', cloneFieldsData);

        if (fieldsData.id === 0) {
          mostrarModalSucesso(
            'Regulamento Cadastrado com Sucesso',
            'O regulamento entra em vigor a partir de',
            cloneFieldsData.vigencia
          );
        } else {
          mostrarModalSucesso(
            'Regulamento Alterado com Sucesso',
            'As alterações no regulamento entram em vigor a partir de',
            cloneFieldsData.vigencia
          );
        }

        setLoading({ ...loading, salvar: false });

        onSalvar();
      } catch (e) {
        validarErroRegulamento(e);
        setLoading({ ...loading, salvar: false });
      }
    } else {
      setLoading({ ...loading, salvar: false });
      Modal.error({
        title: 'Ops...',
        content: validaRegulamento(fieldsData),
        centered: true,
      });
    }
  };

  const [setCamposValidos] = useState(true);
  const [validateCamposExistentes, setValidateCamposExistentes] = useState({
    nome_regulamento: false,
    vigencia: false,
  });

  const validaCampoExistente = async (regulamento) => {
    if (regulamento.vigencia !== undefined) {
      const validacoesRegulamento = await defaultGet(
        {
          idEmpresa: regulamento.id_empresa,
          idLocal: regulamento.id_local,
          idFolha: regulamento.id_folha,
          nomeRegulamento: regulamento.nome_regulamento,
          vigencia: moment(regulamento.vigencia).startOf('month').format('DD/MM/YYYY'),
        },
        'regulamento/validarCampos'
      );

      if (validacoesRegulamento) {
        setValidateCamposExistentes({ ...validacoesRegulamento });
        const isInvalido = Object.values(validacoesRegulamento).some((item) => item === true);
        setCamposValidos(!isInvalido);
      }
    } else {
      setValidateCamposExistentes({
        nome_regulamento: false,
        vigencia: false,
      });
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={9}
          lg={9}
          xl={9}
        >
          <Label label={<CustomLabel8>Dia limite para lançamentos</CustomLabel8>}>
            <CustomInputNumber
              min={1}
              max={31}
              placeholder="DD"
              value={fieldsData.dia_limite_lancamento}
              onChange={(value) => {
                setFieldsData({ ...fieldsData, dia_limite_lancamento: value });
              }}
              disabled={acesso.isGestor() || lancamentoFinalizado}
            />
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={9}
          lg={9}
          xl={9}
        >
          <Label label={<CustomLabel8>Dia virada da competência</CustomLabel8>}>
            <CustomInputNumber
              min={1}
              max={31}
              placeholder="DD"
              value={fieldsData.dia_atualizacao_competencia}
              onChange={(value) => {
                setFieldsData({
                  ...fieldsData,
                  dia_atualizacao_competencia: value,
                });
              }}
              disabled={acesso.isGestor() || lancamentoFinalizado}
            />
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Label label={<CustomLabel8>Nome do Regulamento</CustomLabel8>}>
            <Input
              value={fieldsData.nome_regulamento}
              placeholder="Inisira um nome para esse regulamento..."
              onChange={(e) => {
                setFieldsData({
                  ...fieldsData,
                  nome_regulamento: e.target.value,
                });
              }}
              disabled={lancamentoFinalizado || fieldsData.vigencia === undefined}
              onBlur={() => validaCampoExistente(fieldsData)}
            />
          </Label>
          {validateCamposExistentes.nome_regulamento && (
            <CustomText color="#cc1d33">* Nome de regulamento já existe</CustomText>
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Label
            label={
              <div style={{ display: 'flex' }}>
                <CustomLabel8>Emails dos facilitadores para alerta</CustomLabel8>
              </div>
            }
          >
            <TagsEmail
              emails={fieldsData.email_alerta}
              disabled={acesso.isGestor() || lancamentoFinalizado}
              onChange={(emails) => {
                setFieldsData({
                  ...fieldsData,
                  email_alerta: emails.join(';'),
                });
              }}
            />
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Label
            label={
              <div style={{ display: 'flex' }}>
                <CustomLabel8>Emails dos diretores para alerta</CustomLabel8>
              </div>
            }
          >
            <TagsEmail
              emails={fieldsData.email_alerta_diretores}
              disabled={acesso.isGestor() || lancamentoFinalizado}
              onChange={(emails) => {
                if (emails) {
                  setFieldsData({
                    ...fieldsData,
                    email_alerta_diretores: emails.join(';'),
                  });
                }
              }}
            />
          </Label>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{ marginTop: '30px' }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
        >
          <Checkbox
            checked={fieldsData.solicita_vr}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                solicita_vr: e.target.checked,
                solicita_vr_valor: 0,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Empresa define valor de Refeição (dia) ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
        >
          <Label label="Valor Padrão">
            <InputMoney
              prefix="R$"
              value={fieldsData.solicita_vr_valor}
              disabled={!fieldsData.solicita_vr || lancamentoFinalizado}
              getValue={(value) => {
                setFieldsData({ ...fieldsData, solicita_vr_valor: value });
              }}
            />
          </Label>
        </Col>

        <Col span={24}>
          <Row
            gutter={[16, 8]}
            align="middle"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={14}
              xl={14}
            >
              <Checkbox
                checked={fieldsData.solicita_va}
                onChange={(e) => {
                  setFieldsData({
                    ...fieldsData,
                    solicita_va: e.target.checked,
                    solicita_va_valor: 0,
                  });
                }}
                disabled={lancamentoFinalizado}
              >
                <CustomLabel6>Empresa define valor de Alimentação (mês)?</CustomLabel6>
              </Checkbox>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
            >
              <Label label="Valor Padrão">
                <InputMoney
                  prefix="R$"
                  value={fieldsData.solicita_va_valor}
                  disabled={!fieldsData.solicita_va || lancamentoFinalizado}
                  getValue={(value) => {
                    setFieldsData({ ...fieldsData, solicita_va_valor: value });
                  }}
                />
              </Label>
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
        >
          <Checkbox
            checked={fieldsData.considera_dias_fixos}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                considera_dias_fixos: e.target.checked,
                qtd_dias_fixos: 0,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Considera dias fixos ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
        >
          <Label label="Quantidade de dias">
            <CustomInputNumber
              min={0}
              max={31}
              value={fieldsData.qtd_dias_fixos}
              disabled={!fieldsData.considera_dias_fixos || lancamentoFinalizado}
              onChange={(value) => {
                setFieldsData({ ...fieldsData, qtd_dias_fixos: value });
              }}
            />
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={14}
        >
          <Checkbox
            checked={fieldsData.alerta_pedido_sup_inf}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                alerta_pedido_sup_inf: e.target.checked,
                alerta_pedido_sup_inf_valor: 0,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>
              Alertar quando o total de rendimentos for x% superior em relação à remuneração base
            </CustomLabel6>
          </Checkbox>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
        >
          <Label label="Porcentagem">
            <InputMoney
              prefix="%"
              value={fieldsData.alerta_pedido_sup_inf_valor}
              disabled={!fieldsData.alerta_pedido_sup_inf || lancamentoFinalizado}
              getValue={(value) => {
                setFieldsData({
                  ...fieldsData,
                  alerta_pedido_sup_inf_valor: value,
                });
              }}
            />
          </Label>
        </Col>
      </Row>

      <Row
        gutter={[8, 64]}
        style={{ marginTop: '30px' }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={8}
        >
          <Checkbox
            checked={fieldsData.proporcionaliza_ferias}
            onChange={(e) => {
              if (e.target.checked === false) {
                setFieldsData({
                  ...fieldsData,
                  proporcionaliza_ferias: e.target.checked,
                  considera_prog_ferias: false,
                });
              } else {
                setFieldsData({
                  ...fieldsData,
                  proporcionaliza_ferias: e.target.checked,
                });
              }
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Proporcionaliza Férias ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
        >
          <Checkbox
            checked={fieldsData.proporcionaliza_afastamento}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                proporcionaliza_afastamento: e.target.checked,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Proporcionaliza Afastamento ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col span={24}>
          <Checkbox
            checked={fieldsData.vr_diferente_colab}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                vr_diferente_colab: e.target.checked,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Valor de Vale Refeição diferente entre colaboradores ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col span={24}>
          <Checkbox
            checked={fieldsData.trans_vr_para_va}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                trans_vr_para_va: e.target.checked,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            <CustomLabel6>Transfere Vale Refeição para Vale Alimentação ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col span={24}>
          <Checkbox
            checked={fieldsData.comp_dif_colab}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                comp_dif_colab: e.target.checked,
              });
            }}
            disabled={lancamentoFinalizado}
          >
            Composição diferente entre colaboradores ?
          </Checkbox>
        </Col>

        <Col span={24}>
          <Checkbox
            disabled={!fieldsData.proporcionaliza_ferias || lancamentoFinalizado}
            checked={fieldsData.considera_prog_ferias}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                considera_prog_ferias: e.target.checked,
              });
            }}
          >
            <CustomLabel6>Considerar Programação de Férias ?</CustomLabel6>
          </Checkbox>
        </Col>

        <Col span={24}>
          <Checkbox
            disabled={!fieldsData.proporcionaliza_afastamento || lancamentoFinalizado}
            checked={fieldsData.considera_prog_afastamento}
            onChange={(e) => {
              setFieldsData({
                ...fieldsData,
                considera_prog_afastamento: e.target.checked,
              });
            }}
          >
            <CustomLabel6>Considerar Programação de Afastamentos ?</CustomLabel6>
          </Checkbox>
        </Col>
      </Row>

      <Row justify="end">
        <Col
          style={{
            display: 'inline-grid',
            gridAutoFlow: 'column',
            columnGap: '8px',
            marginBottom: '10px',
          }}
        >
          {fieldsData.id ? (
            <>
              {!lancamentoFinalizado && (
                <>
                  <Button
                    type="primary"
                    onClick={sendRegulamentos}
                    loading={loading.salvar}
                  >
                    Alterar
                  </Button>
                  <Button
                    onClick={desabilitarRegulamentos}
                    loading={loading.desabilitar}
                  >
                    Desabilitar
                  </Button>
                </>
              )}
            </>
          ) : (
            <Button
              type="primary"
              onClick={sendRegulamentos}
              loading={loading.salvar}
            >
              Salvar
            </Button>
          )}
          <Button onClick={onVoltar}>Voltar</Button>
        </Col>
      </Row>
    </>
  );
};

FormularioTela.propTypes = {
  onVoltar: propTypes.func.isRequired,
  onSalvar: propTypes.func.isRequired,
  onDesabilitar: propTypes.func.isRequired,
  lancamentoFinalizado: propTypes.bool,
  regulamentoExistente: propTypes.shape(),
};

FormularioTela.defaultProps = {
  lancamentoFinalizado: false,
  regulamentoExistente: undefined,
};

export default FormularioTela;
