import React, { useState } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/es/locale/pt_BR';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { Sidebar, AlertaCookie } from '@4beefopag/4bee-fopag-core/dist/organisms';
import { AuthContext, useAcesso } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import Skeleton from 'antd/es/skeleton';
import { ReactQueryConfigProvider } from 'react-query';
import { useGoogleAnalytics, useHotjar, useSentry } from '@4beefopag/4bee-fopag-core/dist/shared/ui/analytics';
import App from './components/pages/Home';
import RegulamentoBeneficios from './components/pages/RegulamentoBeneficios';
import LancamentoBeneficios from './components/pages/LancamentoBeneficios';
import SolicitacaoTicket from './components/pages/SolicitacaoTicket';
import LancamentoBeneficiosComplementar from './components/pages/LancamentoBeneficiosComplementar';

moment.locale('pt-BR');

const queryConfig = {
  queries: {
    staleTime: 0,
    refetchOnWindowFocus: false,
  },
};

const useAuth = (component, acesso) => {
  if (acesso && acesso.perfil) {
    return component;
  }

  return Skeleton;
};

const Router = () => {
  const { acesso, acessos, isGestor, isAdmin, loading, nome } = useAcesso();

  const inquilino = localStorage.getItem('fopag.auth.tenant');
  const [aceitouCookie, setAceitouCookie] = useState(undefined);

  useHotjar(aceitouCookie, process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
  useGoogleAnalytics(aceitouCookie, process.env.REACT_APP_GA_ID);
  useSentry(process.env.REACT_APP_SENTRY_DNS, process.env.NODE_ENV);

  return (
    <>
      <Sidebar
        titulo="Seja bem-vindo(a) :)"
        subtitulo={nome}
        idModulo={3}
        logo="https://storage.googleapis.com/static-files-4fdd9378-835d-40e4-b124-36699ae5cc08/beneficios/logo/icone-bwg-amarelo.png"
        Link={NavLink}
        acesso={acesso}
        acessos={acessos}
        loading={loading}
        inquilino={inquilino}
      />
      <AuthContext.Provider value={{ acesso, isGestor, isAdmin }}>
        <div id="container">
          <ReactQueryConfigProvider config={queryConfig}>
            <Switch>
              <ConfigProvider locale={ptBr}>
                <Route
                  path="/"
                  exact
                  component={App}
                />
                <Route
                  path="/regulamento"
                  exact
                  component={useAuth(RegulamentoBeneficios, acesso)}
                />
                <Route
                  path="/regulamento/:id"
                  exact
                  component={useAuth(RegulamentoBeneficios, acesso)}
                />
                <Route
                  path="/lancamento"
                  exact
                  component={useAuth(LancamentoBeneficios, acesso)}
                />
                <Route
                  path="/lancamento/:empresa/:local/:folha"
                  exact
                  component={useAuth(LancamentoBeneficios, acesso)}
                />
                <Route
                  path="/ticket/solicitar"
                  exact
                  component={useAuth(SolicitacaoTicket, acesso)}
                />
                <Route
                  path="/lancamento/complementar/:id"
                  exact
                  component={useAuth(LancamentoBeneficiosComplementar, acesso)}
                />
              </ConfigProvider>
            </Switch>
          </ReactQueryConfigProvider>
        </div>
      </AuthContext.Provider>
      <AlertaCookie
        cookieName="cookie-beneficios"
        onAccept={() => setAceitouCookie(true)}
      />
    </>
  );
};

export default Router;
