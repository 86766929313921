import React from 'react';
import PropTypes from 'prop-types';
import { moneyFormat } from '@4beefopag/4bee-fopag-core/dist/util';
import styled from 'styled-components';
import { CustomText } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { regulamentoProps } from '../../atoms/Props';

const DefaultLabel = styled(CustomText)`
  font-size: 12px;
  color: #262626;
  height: 16px;
`;

const TdRight = styled.td`
  padding: 8px !important;
  text-align: right !important;
`;

const TotalTabela = ({ eventos, regulamentos }) => (
  <tr>
    <td />
    <td />
    <td />
    <td />
    <TdRight>
      <DefaultLabel>{moneyFormat(eventos.find((evento) => evento.coluna === 'refeicao_dia').total)}</DefaultLabel>
    </TdRight>
    {regulamentos.comp_dif_colab && <td />}
    <TdRight>
      <DefaultLabel>{moneyFormat(eventos.find((evento) => evento.coluna === 'valor_refeicao').total)}</DefaultLabel>
    </TdRight>
    {/* removido `regulamentos.solicita_va` por solicitação da nayara,
    não necessariamente o gestor precisa definir valor de TA para habilitar transferencia,
    vide bug:

    https://4beebwg.atlassian.net/browse/FP2-1724
    */}
    {regulamentos.trans_vr_para_va && (
      <TdRight>
        <DefaultLabel>
          {moneyFormat(eventos.find((evento) => evento.coluna === 'tranfere_para_alimentacao').total)}
        </DefaultLabel>
      </TdRight>
    )}
    {regulamentos.comp_dif_colab && <td />}
    <TdRight>
      <DefaultLabel>{moneyFormat(eventos.find((evento) => evento.coluna === 'valor_alimentacao').total)}</DefaultLabel>
    </TdRight>
  </tr>
);

TotalTabela.propTypes = {
  eventos: PropTypes.arrayOf(
    PropTypes.shape({
      coluna: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
  regulamentos: regulamentoProps,
};

TotalTabela.defaultProps = {
  eventos: [{}],
  regulamentos: {},
};

export default TotalTabela;
