// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root{
    --primary: #1666c0;
    --orange: #F4A01D;
    --blue: #0B8AF7;
    --error: #B00020;
    --success: #388E3C;

    --system-bg: #F6F7F9;

    --text-grey-8: #262626;
    --text-grey-7: #464748;
    --text-grey-6: #656668;
    --text-grey-5: #A3A4A5;
    --text-grey-4: #C1C2C3;
    --text-grey-3: #E4E5E7;
    --text-grey-2: #F0F1F3;
    --text-grey-1: #F6F7F9;
  }

  #root{
    display: flex;
    height: 100%;
  }

  #container{
    background: #f6f7f9;
    height: 100%;
    overflow-y: auto;
    max-height: 100vh;
    flex: 1;
  }

  #root .ant-select-focused .ant-select-selection, #root .ant-select-selection:focus, #root .ant-select-selection:active {
    border-color: #fa075a;
    -webkit-box-shadow: 0 0 0 2px rgba(250, 7, 90, 0.2);
    box-shadow: 0 0 0 2px rgba(250, 7, 90, 0.2);
  }

  #root .ant-input:hover, #root .ant-input:focus {
    border-color: #fa075a;
    border-right-width: 1px !important;
  }

  #root .ant-input-number:focus, #root .ant-input-number:hover, #root .ant-input-number:active {
    border-color: #fa075a;
    border-right-width: 1px !important;
  }

  button.ant-btn {
    min-width: 112px;
  }

`;

export default GlobalStyle;
