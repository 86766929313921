import PropTypes from 'prop-types';

export const colaboradorProps = PropTypes.shape({
  cpf: PropTypes.string.isRequired,
  nome: PropTypes.string.isRequired,
  idContratado: PropTypes.number.isRequired,
  valorHora: PropTypes.number.isRequired,
  remuneracaoBase: PropTypes.number.isRequired,
  dataAdesao: PropTypes.instanceOf(Date).isRequired,
});

export const regulamentoProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  inquilino: PropTypes.number.isRequired,
  id_empresa: PropTypes.number.isRequired,
  id_local: PropTypes.number.isRequired,
  id_folha: PropTypes.number.isRequired,
  nome_regulamento: PropTypes.string.isRequired,
  solicita_vr: PropTypes.bool.isRequired,
  solicita_vr_valor: PropTypes.number.isRequired,
  solicita_va: PropTypes.bool.isRequired,
  solicita_va_valor: PropTypes.number.isRequired,
  considera_dias_fixos: PropTypes.bool.isRequired,
  qtd_dias_fixos: PropTypes.number.isRequired,
  proporcionaliza_ferias: PropTypes.bool.isRequired,
  proporcionaliza_afastamento: PropTypes.bool.isRequired,
  proporcionaliza_rescisao: PropTypes.bool.isRequired,
  vr_diferente_colab: PropTypes.bool.isRequired,
  trans_vr_para_va: PropTypes.bool.isRequired,
  comp_dif_colab: PropTypes.bool.isRequired,
  considera_prog_afastamento: PropTypes.bool.isRequired,
  considera_prog_ferias: PropTypes.bool.isRequired,
  email_alerta: PropTypes.string.isRequired,
  dia_atualizacao_competencia: PropTypes.number.isRequired,
  mes_vigencia: PropTypes.number.isRequired,
  ano_vigencia: PropTypes.number.isRequired,
  dia_limite_lancamento: PropTypes.number.isRequired,
  email_alerta_diretores: PropTypes.string.isRequired,
  alerta_pedido_sup_inf: PropTypes.bool.isRequired,
  alerta_pedido_sup_inf_valor: PropTypes.number.isRequired,
});
