import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import Router from './Router';

const App = () => (
  // Reason for being in App and not in Router:
  //   https://github.com/remix-run/react-router/issues/7015#issuecomment-548420654
  //
  //   > You can't use any of the hooks from within the same component
  //   > that puts the Router into the tree.
  //   > You need to move your BrowserRouter out of that component.
  //   > It can go in the ReactDOM.render() call, for instance.
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
