import React, { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Row, Select } from 'antd';
import { CustomLabel8, CustomSelect, Label } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { useQuery } from 'react-query';
import { getEmpresas, getFolhas, getLocais, http } from '@4beefopag/4bee-fopag-core/dist/services';
import propTypes from 'prop-types';
import moment from 'moment';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const ParametrosTela = ({ empresa, local, folha, vigencia, proximaEtapa, onChange }) => {
  const [state, setState] = useState({
    empresa,
    local,
    folha,
    vigencia,
    erro: undefined,
    loading: false,
  });

  const { isLoading: loadingEmpresas, data: empresas } = useQuery('empresas', () => getEmpresas(0));

  const { isLoading: loadingLocais, data: locais } = useQuery(['locais', state.empresa], () =>
    getLocais(state.empresa)
  );

  const { isLoading: loadingFolhas, data: folhas } = useQuery(['folhas', state.local], () => getFolhas(state.local));

  useEffect(() => {
    onChange({
      empresa: state.empresa,
      local: state.local,
      folha: state.folha,
      vigencia: state.vigencia,
    });
  }, [state.empresa, state.local, state.folha, state.vigencia]);

  const verificarParametros = async () => {
    setState({ ...state, loading: true, erro: undefined });
    try {
      await http.get('regulamento/validarCampos', {
        params: {
          idEmpresa: state.empresa,
          idLocal: state.local,
          idFolha: state.folha,
          nomeRegulamento: '',
          vigencia: moment(state.vigencia).startOf('month').format('DD/MM/YYYY'),
        },
      });

      setState({ ...state, loading: false });

      proximaEtapa();
    } catch (e) {
      let mensagem = 'Algo de errado aconteceu, por favor tente novamente.';

      if (e.response && e.response.data && e.response.data.status === 400) {
        mensagem = e.response.data.message;
      }

      setState({ ...state, loading: false, erro: mensagem });
    }
  };

  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Label label={<CustomLabel8>Empresa</CustomLabel8>}>
            <CustomSelect
              loading={loadingEmpresas}
              placeholder="Selecione uma empresa..."
              allowClear
              value={state.empresa}
              onChange={(value) => {
                setState({
                  ...state,
                  empresa: value,
                  local: undefined,
                  folha: undefined,
                });
              }}
            >
              {(empresas || []).map((item) => (
                <Option
                  key={item.idEmpresa}
                  value={item.idEmpresa}
                >
                  {`${item.idEmpresa} - ${item.razaoSocial}`}
                </Option>
              ))}
            </CustomSelect>
          </Label>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Label label={<CustomLabel8>Local</CustomLabel8>}>
            <Select
              placeholder="Selecione um local..."
              loading={loadingLocais}
              allowClear
              value={state.local}
              onChange={(value) => {
                setState({
                  ...state,
                  local: value,
                  folha: undefined,
                });
              }}
            >
              {(locais || []).map((item) => (
                <Option
                  key={item.idLocal}
                  value={item.idLocal}
                >
                  {`${item.idLocal} - ${item.descricao}`}
                </Option>
              ))}
            </Select>
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Label label={<CustomLabel8>Folha</CustomLabel8>}>
            <Select
              placeholder="Selecione uma folha..."
              loading={loadingFolhas}
              allowClear
              value={state.folha}
              onChange={(value) => {
                setState({
                  ...state,
                  folha: value,
                });
              }}
            >
              {(folhas || []).map((item) => (
                <Option
                  key={item.idFolha}
                  value={item.idFolha}
                >
                  {`${item.idFolha} - ${item.descricao}`}
                </Option>
              ))}
            </Select>
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
        >
          <Label label={<CustomLabel8>Vigência</CustomLabel8>}>
            <MonthPicker
              // disabledDate={disabledMonth}
              value={state.vigencia}
              onChange={(value) => {
                setState({ ...state, vigencia: value });
              }}
              suffixIcon={<CalendarOutlined />}
              placeholder="MM/YYYY"
              defaultValue={state.vigencia}
              format="MM/YYYY"
              allowClear={false}
            />
          </Label>
        </Col>
      </Row>

      {state.erro && (
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            <Alert
              message={state.erro}
              type="error"
              showIcon
              closable
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            onClick={() => verificarParametros()}
            loading={state.loading}
            disabled={!state.empresa || !state.vigencia}
          >
            Prosseguir
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ParametrosTela.propTypes = {
  empresa: propTypes.number,
  local: propTypes.number,
  folha: propTypes.number,
  vigencia: propTypes.instanceOf(moment),
  proximaEtapa: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

ParametrosTela.defaultProps = {
  empresa: undefined,
  local: undefined,
  folha: undefined,
  vigencia: undefined,
};

export default ParametrosTela;
