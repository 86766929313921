import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { PreviewTela } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { Button, Col, Modal, Row, Skeleton, Spin, Upload } from 'antd';
import { Card, ContornoTabela, CustomText, Label } from '@4beefopag/4bee-fopag-core/dist/atoms';
import { moneyFormat, urlbaseBackend } from '@4beefopag/4bee-fopag-core/dist/util';
import PropTypes from 'prop-types';
import { useHttpRequest, useAuthHeader, AuthContext } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import { defaultGet, http, imprimir } from '@4beefopag/4bee-fopag-core/dist/services';
import TabelaBeneficiosComplementar from '../../organisms/TabelaBeneficiosComplementar';
import useValidacaoComplementar from './useValidacaoComplementar';
import BeneficiosPage from '../../molecules/BeneficiosPage';

function LancamentoBeneficiosComplementar({ match }) {
  const auth = useAuthHeader();
  const acesso = useContext(AuthContext);

  const [nome] = useState('');
  const [pagina] = useState(0);
  const [total, setTotal] = useState('-');
  const [loading, setLoading] = useState({
    finalizar: false,
    salvar: false,
    exportar: false,
    importar: false,
  });

  const {
    state: { data: beneficios, loading: loadingColaboradores },
    setData: setStateBeneficios,
    request: doRequestBeneficios,
  } = useHttpRequest(
    () =>
      defaultGet(
        {
          idCabecalho: match.params.id,
          nome,
          pagina,
        },
        '/beneficios/complementar'
      ),
    {
      total: 0,
      colaboradores: [],
      cabecalho: {},
      conteudoComplementar: {
        prazoFinalizacao: '',
        razaoSocial: '',
        descricaoLocal: '',
        descricaoFolha: '',
      },
    },
    [match.params.id, nome, pagina]
  );

  const validacao = useValidacaoComplementar(beneficios ? beneficios.cabecalho : {}, acesso);

  /**
   * Atualiza as listas de colaboradores da tela com o novo valor alterado no input
   * @param {(object)} itensCalculo - Linha alterada
   */
  const atualizaListasColaboradores = (itensCalculo) => {
    const colaboradores = [...beneficios.colaboradores];

    const idx = colaboradores.findIndex((c) => c.colaborador.funcCodigo === itensCalculo.colaborador.funcCodigo);
    colaboradores[idx] = itensCalculo;

    setStateBeneficios({ ...beneficios, colaboradores: [...colaboradores] });
  };

  /**
   * Define valor para o campo da tabela
   * @param {(object)} itensCalculo - Valor correspondente a linha do colaborador na tabela
   * @param {(string)} key - Chave da coluna alterada
   * @param {('number'|'')} type - Tipo do campo a ser alterado
   */
  const setValueField = (itensCalculo, key, type) => (e) => {
    const newItensCalculo = { ...itensCalculo };

    if (type === 'number') {
      newItensCalculo[key] = parseInt(e.target.value, 10);
    } else {
      newItensCalculo[key] = e;
    }

    atualizaListasColaboradores(newItensCalculo);
  };

  // ----------- Funções para as ações dos botões -------------

  /**
   * Envia os dados em lista da solicitação de beneficios.
   * @param {('salvar'|'finalizar')} type - Tipo da requisição a ser enviada
   * @param {(boolean)} exibeFeedback - Verifica se exibe Modal de sucesso ou erro.
   */
  const onSendBeneficios = async (type, exibeFeedback = true) => {
    if (type === 'salvar') {
      setLoading({ ...loading, salvar: true });
    } else {
      setLoading({ ...loading, finalizar: true });
    }

    const lancamentoBeneficiosList = beneficios.colaboradores.map((itensDaLinha) => ({
      idCabecalho: match.params.id,
      idContratado: itensDaLinha.colaborador.funcCodigo,
      valorAlimentacao: itensDaLinha.valorAlimentacao || 0,
      valorRefeicao: itensDaLinha.valorRefeicao || 0,
      dados: JSON.stringify(itensDaLinha.colaborador),
    }));

    try {
      await http.post(`/beneficios/complementar/${type}`, {
        cabecalho: beneficios.cabecalho,
        lancamentoBeneficiosList,
        valorTotal: total,
      });
      if (exibeFeedback) {
        doRequestBeneficios();

        const msg =
          type === 'salvar'
            ? 'Benefícios salvos com sucesso. Caso deseje enviar, utilize a opção "Finalizar".'
            : 'Benefícios enviados com sucesso!';

        Modal.success({
          title: 'Sucesso',
          content: <p>{msg}</p>,
        });
      }
    } catch (e) {
      if (exibeFeedback) {
        if (e.request.status !== 500 && e.response && e.response.data) {
          Modal.error({
            content: e.response.data.message,
          });
        } else {
          Modal.error({
            content: 'Algo de errado aconteceu, por favor tente novamente.',
          });
        }
      }
    }

    if (type === 'salvar') {
      setLoading({ ...loading, salvar: false });
    } else {
      setLoading({ ...loading, finalizar: false });
    }
  };

  /**
   * Exporta os dados da tabela de lançamento de beneficios.
   * @returns {fileDownload} Arquivo excel
   */
  const onExportar = async () => {
    setLoading({ ...loading, exportar: true });

    const lancamentoBeneficiosList = beneficios.colaboradores.map((itensDaLinha) => ({
      idCabecalho: match.params.id,
      idContratado: itensDaLinha.colaborador.funcCodigo,
      valorAlimentacao: itensDaLinha.valorAlimentacao,
      valorRefeicao: itensDaLinha.valorRefeicao,
      dados: JSON.stringify(itensDaLinha.colaborador),
    }));
    const razaoSocialEmpresa = beneficios.conteudoComplementar.razaoSocial;
    const razaoSocialFolha = beneficios.conteudoComplementar.descricaoFolha;
    const razaoSocialLocal = beneficios.conteudoComplementar.descricaoLocal;
    const nomeArquivo = `${moment().format(
      'MM_YYYY'
    )}_Compl_${razaoSocialEmpresa}_${razaoSocialLocal}_${razaoSocialFolha}.xls`
      .split(' ')
      .join('_');
    imprimir(lancamentoBeneficiosList, '/beneficios/complementar/exportar', nomeArquivo)
      .then(() => {
        Modal.success({
          title: 'Sucesso',
          content: <p>Benefícios exportados com sucesso!</p>,
        });
      })
      .catch((e) => {
        if (e.request.status === 400) {
          Modal.error({
            content: e.response.data.message,
          });
        }
      });
    setLoading({ ...loading, exportar: false });
  };

  /**
   * Importa os dados de uma planilha excel e executa o cálculo e retorna os dados para tela
   */
  const onImportar = (info) => {
    setLoading({ ...loading, importar: true });

    if (info.file.status === 'done') {
      if (info.file.response && info.file.response.length > 0) {
        setStateBeneficios({
          ...beneficios,
          colaboradores: [...info.file.response],
        });

        Modal.success({
          title: 'Sucesso',
          content: <p>Benefícios importados com sucesso!</p>,
        });

        setLoading({ ...loading, importar: false });
      }
    } else if (info.file.status === 'error') {
      Modal.error({
        title: 'Erro',
        content: (
          <p>Ocorreu um erro ao importar o arquivo, verifique o formato e as informações contidas e tente novamente.</p>
        ),
      });

      setLoading({ ...loading, importar: false });
    }
  };

  /**
   * Efeito para validar o valor total
   */
  useEffect(() => {
    if (beneficios && beneficios.colaboradores) {
      let valorTotal = 0;
      beneficios.colaboradores.forEach((colaborador) => {
        valorTotal += colaborador.valorAlimentacao + colaborador.valorRefeicao;
      });
      setTotal(valorTotal);
    }
  }, [beneficios]);

  return (
    <BeneficiosPage titulo="Lançamento de Alimentação e Refeição Complementar">
      <Row
        gutter={26}
        type="flex"
        justify="center"
        style={{ marginBottom: '17px' }}
      >
        <Col style={{ borderRight: '2px solid #c1c2c4' }}>
          <Card style={{ padding: 8 }}>
            <CustomText
              fontSize="12px"
              color="#656668"
            >
              Prazo para finalizar pedido
            </CustomText>
            <CustomText
              fontSize="18px"
              color="#262626"
              strong
            >
              {beneficios && beneficios.cabecalho && beneficios.cabecalho.dataLimite
                ? moment(beneficios.cabecalho.dataLimite).format('DD/MM [às] HH:mm')
                : '-'}
            </CustomText>
          </Card>
        </Col>
        <Col>
          <Card style={{ padding: 8 }}>
            <CustomText
              fontSize="12px"
              color="#656668"
            >
              Valor total
            </CustomText>
            <CustomText
              fontSize="18px"
              color="#262626"
              strong
            >
              {loadingColaboradores ? <Spin size="small" /> : moneyFormat(total)}
            </CustomText>
          </Card>
        </Col>
      </Row>
      {beneficios.colaboradores.length > 0 && (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          xl={9}
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            columnGap: 16,
          }}
        >
          <CustomText
            fontSize="12px"
            color="#656668"
          >
            <Label label="Empresa">
              <CustomText
                fontSize="14px"
                color="#262626"
                strong
              >
                {`${beneficios.cabecalho.idEmpresa} - ${beneficios.conteudoComplementar.razaoSocial}`}
              </CustomText>
            </Label>
          </CustomText>
          <CustomText
            fontSize="12px"
            color="#656668"
          >
            <Label label="Local">
              <CustomText
                fontSize="14px"
                color="#262626"
                strong
              >
                {`${beneficios.cabecalho.idLocal} - ${beneficios.conteudoComplementar.descricaoLocal}`}
              </CustomText>
            </Label>
          </CustomText>
          <CustomText
            fontSize="12px"
            color="#656668"
          >
            <Label label="Folha">
              <CustomText
                fontSize="14px"
                color="#262626"
                strong
              >
                {`${beneficios.cabecalho.idFolha} - ${beneficios.conteudoComplementar.descricaoFolha}`}
              </CustomText>
            </Label>
          </CustomText>
        </Col>
      )}
      <Row style={{ marginTop: '16px' }}>
        <Col span={24}>
          {match.params.id ? (
            <>
              {loadingColaboradores ? (
                <>
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                  />
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                  />
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                  />
                </>
              ) : (
                <>
                  <ContornoTabela>
                    <TabelaBeneficiosComplementar
                      colaboradores={beneficios ? beneficios.colaboradores : []}
                      setValueField={setValueField}
                      permiteEditar={validacao.permiteSalvar}
                    />
                  </ContornoTabela>

                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginTop: '16px' }}
                  >
                    <Col
                      style={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        columnGap: 16,
                      }}
                    >
                      <Button
                        type="primary"
                        loading={loading.finalizar}
                        onClick={() => onSendBeneficios('finalizar')}
                        disabled={!validacao.permiteFinalizar}
                      >
                        Finalizar
                      </Button>
                      <Button
                        loading={loading.salvar}
                        disabled={!validacao.permiteSalvar}
                        onClick={() => onSendBeneficios('salvar')}
                      >
                        Salvar
                      </Button>
                      <Button
                        onClick={onExportar}
                        loading={loading.exportar}
                      >
                        Exportar
                      </Button>
                      <Upload
                        accept=".xls"
                        name="file"
                        action={urlbaseBackend('/beneficios/complementar/importar')}
                        showUploadList={false}
                        onChange={onImportar}
                        headers={{
                          ...auth,
                          idCabecalho: match.params.id,
                        }}
                      >
                        <Button
                          fontSize="12px"
                          height="16px"
                          color="var(--text-grey-8)"
                        >
                          Importar
                        </Button>
                      </Upload>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 12, offset: 5 }}
              >
                <PreviewTela
                  imagem="https://storage.googleapis.com/static-files-4fdd9378-835d-40e4-b124-36699ae5cc08/beneficios/lancamento_beneficios/previewLancamentoBeneficios.png"
                  title="Um pequeno texto falando o que o gestor pode relizar nessa tela…"
                  subTitle={`E o que acontece depois que realizar as ações nessa tela
                              ou o que ele deveria fazer #VamoAeee`}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </BeneficiosPage>
  );
}

LancamentoBeneficiosComplementar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

LancamentoBeneficiosComplementar.defaultProps = {
  match: { params: {} },
};

export default LancamentoBeneficiosComplementar;
