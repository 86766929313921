import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import { CustomText, Table, TableWrapper } from '@4beefopag/4bee-fopag-core/dist/atoms';
import LinhaTabelaComplementar from '../../molecules/LinhaTabelaComplementar';

const DefaultLabel = styled(CustomText)`
  font-size: 12px;
  color: var(--text-grey-6);
  height: 16px;
`;

const TabelaBeneficiosComplementar = ({ colaboradores, setValueField, permiteEditar }) => {
  const wrapperRef = useRef();
  const [isFarLeft, setIsFarLeft] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      setIsFarLeft(currPos.x === 0);
    },
    [wrapperRef, isFarLeft],
    wrapperRef
  );

  return (
    <TableWrapper ref={wrapperRef}>
      <Table
        widthInput="6em"
        background="#fafafa"
        borderBottomTh="1px solid #e8e8e8"
        borderBottomTd="1px solid #e8e8e8"
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            <th>
              <DefaultLabel>CPF</DefaultLabel>
            </th>
            <th>
              <DefaultLabel>Nome</DefaultLabel>
            </th>
            <th>
              <DefaultLabel>Alimentação Complementar</DefaultLabel>
            </th>
            <th>
              <DefaultLabel>Refeição Complementar</DefaultLabel>
            </th>
          </tr>
        </thead>
        <tbody>
          {colaboradores.map((c) => (
            <LinhaTabelaComplementar
              key={c.colaborador.funcCodigo}
              dadosDaLinha={c}
              setValueField={setValueField}
              permiteEditar={permiteEditar}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

TabelaBeneficiosComplementar.propTypes = {
  colaboradores: PropTypes.arrayOf(PropTypes.shape),
  setValueField: PropTypes.func,
  permiteEditar: PropTypes.bool.isRequired,
};

TabelaBeneficiosComplementar.defaultProps = {
  colaboradores: [],
  setValueField: () => {},
};

export default TabelaBeneficiosComplementar;
