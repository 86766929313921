import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { PreviewTela } from '@4beefopag/4bee-fopag-core/dist/molecules';
import { moneyFormat, urlbaseBackend } from '@4beefopag/4bee-fopag-core/dist/util';
import { CalendarOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Modal, Pagination, Row, Select, Skeleton, Spin, Upload } from 'antd';
import { getEmpresas, getFolhas, getLocais, http, imprimir } from '@4beefopag/4bee-fopag-core/dist/services';
import {
  AuthContext,
  useAuthHeader,
  useHttpRequest,
  useMostrarModal,
} from '@4beefopag/4bee-fopag-core/dist/services/hooks';
import {
  Card,
  CustomLabel8,
  CustomSelect,
  CustomText,
  Label,
  TableWrapper,
} from '@4beefopag/4bee-fopag-core/dist/atoms';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { mostrarErro } from '@4beefopag/4bee-fopag-core/dist/molecules.es';
import TabelaBeneficios from '../../organisms/TabelaBeneficios';
import ModalReaberturaAdmin from './ModalReaberturaAdmin';
import ModalReaberturaGestor from './ModalReaberturaGestor';
import ModalPedidoComplementarAdmin from './ModalPedidoComplementarAdmin';
import {
  apurarDiasFolha,
  calcular,
  carregarBeneficios,
  getCompetencias,
  liberarPedidoComplementar,
  reabrirAdmin,
  reabrirGestor,
  useInformacoesCabecalho,
} from '../../../services/lancamentoBeneficios';
import useValidacaoLancamento from '../../../services/lancamentoBeneficios/useValidacaoLancamento';
import BeneficiosPage from '../../molecules/BeneficiosPage';

const { Option } = Select;

const useMomentDate = (intialValue, format) => {
  const formatarData = (data) => {
    if (!data) {
      return undefined;
    }

    return data.format(format);
  };

  const [data, setData] = useState(intialValue);
  const [dataFormatada, setDataFormatada] = useState(formatarData());

  useEffect(() => {
    setDataFormatada(formatarData(data));
  }, [data]);

  return [data, setData, dataFormatada];
};

function LancamentoBeneficios({ match }) {
  const imagem =
    'https://storage.googleapis.com/static-files-4fdd9378-835d-40e4-b124-36699ae5cc08/beneficios/lancamento_beneficios/previewLancamentoBeneficios.png';
  const history = useHistory();
  const auth = useAuthHeader();

  const [loading, setLoading] = useState({
    reabertura: false,
    complementar: false,
    apuracaoDias: false,
    exportar: false,
    importar: false,
    salvar: false,
    finalizar: false,
  });

  // -------------------------------------- Buscas dos Select's -----------------------
  const { isLoading: loadingEmpresas, data: empresas } = useQuery('empresas', () => getEmpresas(0));
  const [empresa, setEmpresa] = useState(undefined);

  const { isLoading: loadingLocais, data: locais } = useQuery(['locais', empresa], () => getLocais(empresa));
  const [local, setLocal] = useState(undefined);

  const { isLoading: loadingFolhas, data: folhas } = useQuery(['folhas', local], () => getFolhas(local));
  const [folha, setFolha] = useState(undefined);

  const [competencia, setCompetencia, competenciaFormatada] = useMomentDate(undefined, 'MM/YYYY');

  const { isLoading: loadingCompetencias, data: competencias } = useQuery(['competencias', folha], () =>
    getCompetencias(empresa, local, folha, setCompetencia)
  );

  useEffect(() => {
    if (match.params && match.params.folha) {
      setEmpresa(parseInt(match.params.empresa, 10));
      setLocal(parseInt(match.params.local, 10));
      setFolha(parseInt(match.params.folha, 10));
    }
  }, [match.params]);

  const initialStatePaginacao = {
    itensPorPagina: 8,
    paginaAtual: 1,
  };
  const [paginacao, setPaginacao] = useState(initialStatePaginacao);

  const [filtroNome, setFiltroNome] = useState(undefined);

  const [nomeArquivoExcel, setNomeArquivoExcel] = useState('');

  // -------------------- Buscas principais ------------

  const [total, setTotal] = useState('-');

  function existemColaboradoresExcedentes(beneficios) {
    return (
      beneficios &&
      beneficios.colaboradores &&
      beneficios.colaboradores.some((colaborador) => {
        const diasDesconto = colaborador.eventos.find((e) => e.coluna === 'dias_desconto').valor;
        const quantidadeDias = colaborador.eventos.find((e) => e.coluna === 'quantidade_dias').valor;
        const diasAdicionais = colaborador.eventos.find((e) => e.coluna === 'dias_adicionais').valor;
        return diasDesconto > quantidadeDias + diasAdicionais;
      })
    );
  }

  const {
    state: { data: beneficios, loading: loadingColaboradores },
    setData: setStateBeneficios,
    request: doRequestBeneficios,
  } = useHttpRequest(
    async () => {
      const newBeneficios = await carregarBeneficios(empresa, local, folha, competencia, competenciaFormatada);

      if (existemColaboradoresExcedentes(newBeneficios) && folha) {
        Modal.warning({
          centered: true,
          width: 560,
          title: 'Dias de Desconto',
          content: (
            <CustomText
              fontSize="12px"
              color="#656668"
            >
              A quantidade de dias de desconto não pode superar os dias trabalhados.
            </CustomText>
          ),
          okButtonProps: {
            ghost: true,
            style: {
              borderColor: '#c1c2c4',
            },
          },
        });
      }
      return newBeneficios;
    },
    { total: 0, colaboradores: [] },
    [folha, competenciaFormatada]
  );

  const informacoesCabecalho = useInformacoesCabecalho(
    competencia,
    competenciaFormatada,
    beneficios && beneficios.regulamento ? beneficios.regulamento.dia_limite_lancamento : undefined
  );

  const filtrar = () => {
    if (beneficios) {
      let cf = beneficios.colaboradores || [];

      if (filtroNome) {
        cf = cf.filter(
          (c) =>
            c.colaborador.nome
              .toLowerCase()
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
              .indexOf(
                filtroNome
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
              ) > -1
        );
      }

      const totalColaboradores = cf.length;

      cf = cf.slice(
        (paginacao.paginaAtual - 1) * paginacao.itensPorPagina,
        paginacao.paginaAtual * paginacao.itensPorPagina
      );

      return { itens: cf, total: totalColaboradores };
    }
    return { itens: [], total: 0 };
  };

  const [colaboradoresFiltrados, setColaboradoresFiltrados] = useState(filtrar());

  useEffect(() => {
    setColaboradoresFiltrados(filtrar());
  }, [beneficios.colaboradores, paginacao.itensPorPagina, paginacao.paginaAtual]);

  const alterarPaginacao = useCallback((page, pageSize) => {
    setPaginacao({ ...paginacao, paginaAtual: page, itensPorPagina: pageSize });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPaginacao({ ...paginacao, paginaAtual: 1 });
      setColaboradoresFiltrados(filtrar());
    }, 400);
    return () => clearTimeout(timer);
  }, [filtroNome]);

  const atualizaColaboradorCalculado = (calculado) => {
    const colaboradores = [...beneficios.colaboradores];

    const idx = colaboradores.findIndex((c) => c.colaborador.funcCodigo === calculado.colaborador.funcCodigo);
    colaboradores[idx] = calculado;

    setStateBeneficios({ ...beneficios, colaboradores: [...colaboradores] });
  };

  /**
   * Calcula manualmente o valor de eventos
   * @param {number} funcCodigo - Valor correspondente a linha do colaborador na tabela
   * @param {eventos} eventos - Dados da tabela do colaborador
   */
  const calcularManual = (funcCodigo, eventos) => {
    const idx = beneficios.colaboradores.findIndex((c) => c.colaborador.funcCodigo === funcCodigo);

    const colaboradoresTemp = [...beneficios.colaboradores];
    const colaboradorAlterado = colaboradoresTemp[idx];

    if (colaboradorAlterado) {
      const eventosNovos = colaboradorAlterado.eventos.map((item) => ({
        ...item,
      }));

      const eventosSubstituidos = Object.keys(eventos);

      for (let i = 0; i < eventosSubstituidos.length; i += 1) {
        const evento = eventosSubstituidos[i];
        eventosNovos.find((e) => e.coluna === evento).valor = eventos[evento];
        const evtEncontrado = eventosNovos.find((e) => e.coluna === `${evento}_calculado_manual`);
        if (!evtEncontrado) {
          eventosNovos.push({
            coluna: `${evento}_calculado_manual`,
            valor: 1,
          });
        }
      }

      colaboradoresTemp[idx].eventos = eventosNovos;

      const calculado = calcular(colaboradoresTemp[idx], beneficios.regulamento);

      atualizaColaboradorCalculado(calculado);
    }
  };

  // ----------- Funções para as ações dos botões -------------

  const sendBeneficios = async (exibeFeedback, type, modelEnvio) => {
    try {
      await http.post(`/beneficios/lancamento/${type}`, modelEnvio);

      if (exibeFeedback && type === 'finalizar') {
        await doRequestBeneficios();
      }

      if (exibeFeedback) {
        const msg =
          type === 'salvar'
            ? 'Benefícios salvos com sucesso. Caso deseje enviar, utilize a opção "Finalizar".'
            : 'Pedido enviado para processamento com Sucesso. Assim que finalizado, você receberá um e-mail com a confirmação.';

        Modal.success({
          centered: true,
          width: 560,
          title: (
            <CustomText
              color="var(--text-grey-8)"
              fontSize={16}
            >
              Sucesso
            </CustomText>
          ),
          okButtonProps: {
            ghost: true,
            style: {
              borderColor: '#c1c2c4',
            },
          },
          content: (
            <CustomText
              color="var(--text-grey-6)"
              fontSize={14}
            >
              {msg}
            </CustomText>
          ),
        });
      }
    } catch (err) {
      if (exibeFeedback) {
        if (err.request.status !== 500 && err.response && err.response.data) {
          Modal.warning({
            content: <pre>{err.response.data.message}</pre>,
          });

          if (exibeFeedback && type === 'finalizar') {
            doRequestBeneficios();
          }
        } else {
          Modal.error({
            content: 'Algo de errado aconteceu, por favor tente novamente.',
          });
        }
      }
    }
  };

  /**
   * Envia os dados em lista da solicitação de beneficios.
   * @param {('salvar'|'finalizar')} type - Tipo da requisição a ser enviada
   * @param {(boolean)} exibeFeedback - Verifica se exibe Modal de sucesso ou erro.
   */
  const onSendBeneficios = async (type, exibeFeedback = true) => {
    if (exibeFeedback) {
      setLoading({ ...loading, [type]: true });
    }

    const contratados = beneficios.colaboradores.map((itensDaLinha) => ({
      idContratado: itensDaLinha.colaborador.funcCodigo,
      dados: JSON.stringify(itensDaLinha),
    }));

    const nomeArquivoExcelFinalizacao = nomeArquivoExcel.replace('Mens', 'Final');

    const modelEnvio = {
      cabecalho: {
        idEmpresa: empresa,
        idLocal: local,
        idFolha: folha,
        mesCompetencia: moment.utc(competencia).format('MM'),
        anoCompetencia: moment.utc(competencia).format('YYYY'),
      },
      contratados,
      valorTotal: moneyFormat(total),
      nomeArquivoExcel: nomeArquivoExcelFinalizacao,
    };

    if (type === 'finalizar') {
      try {
        const response = await http.post('/beneficios/lancamento/validaColaboradoresExcedentes', modelEnvio);

        if (response.data.length > 0) {
          Modal.confirm({
            centered: true,
            width: 560,
            title: (
              <CustomText
                color="var(--text-grey-8)"
                fontSize={16}
              >
                Excedeu o valor previsto para Alimentação e Refeição
              </CustomText>
            ),
            okButtonProps: {
              ghost: true,
              style: {
                borderColor: '#c1c2c4',
              },
            },
            okText: 'Sim',
            cancelText: 'Não',
            onOk: async () => {
              await sendBeneficios(exibeFeedback, type, modelEnvio);
            },
            content: (
              <>
                <CustomText
                  color="var(--text-grey-8)"
                  fontSize="12px"
                >
                  Os profissionais listados a baixo estão com valores fora do limite previsto para os benefícios
                  alimentação / refeição
                </CustomText>
                <br />
                <br />
                <TableWrapper>
                  <table>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          <CustomText
                            fontSize="12px"
                            color="var(--text-grey-5)"
                            height="12px"
                            strong
                          >
                            Nome
                          </CustomText>
                        </th>
                        <th>
                          <CustomText
                            fontSize="12px"
                            color="var(--text-grey-5)"
                            height="12px"
                            strong
                          >
                            Valor Atual
                          </CustomText>
                        </th>
                        <th>
                          <CustomText
                            fontSize="12px"
                            color="var(--text-grey-5)"
                            height="12px"
                            strong
                          >
                            Valor Mês Anterior
                          </CustomText>
                        </th>
                        <th>
                          <CustomText
                            fontSize="12px"
                            color="var(--text-grey-5)"
                            height="12px"
                            strong
                          >
                            Porcentagem Diferença
                          </CustomText>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.data.map((colaboradorExcedente) => (
                        <tr key={colaboradorExcedente.funcCodigo}>
                          <td />
                          <td>
                            <CustomText
                              color="var(--text-grey-8)"
                              fontSize="12px"
                              strong
                            >
                              {colaboradorExcedente.nome}
                            </CustomText>
                          </td>
                          <td>
                            <CustomText
                              color="var(--text-grey-6)"
                              fontSize="12px"
                              strong
                            >
                              {moneyFormat(colaboradorExcedente.valorAtual)}
                            </CustomText>
                          </td>
                          <td>
                            <CustomText
                              color="var(--text-grey-6)"
                              fontSize="12px"
                              strong
                            >
                              {moneyFormat(colaboradorExcedente.valorMesAnt)}
                            </CustomText>
                          </td>
                          <td>
                            <CustomText
                              color="var(--success)"
                              fontSize="12px"
                              strong
                            >
                              {colaboradorExcedente.porcentagemDiferenca}%
                            </CustomText>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableWrapper>
                <br />
                <CustomText
                  color="var(--text-grey-8)"
                  fontSize="12px"
                >
                  Gostaria de seguir com o envio ?
                </CustomText>
              </>
            ),
          });
        } else {
          await sendBeneficios(exibeFeedback, type, modelEnvio);
        }
      } catch (e) {
        mostrarErro(e);
      }
    } else {
      await sendBeneficios(exibeFeedback, type, modelEnvio);
    }

    if (exibeFeedback) {
      setLoading({ ...loading, [type]: false });
    }
  };

  /**
   * Importa os dados de uma planilha excel e executa o cálculo e retorna os dados para tela
   */
  const onImportar = (info) => {
    if (info.file.status === 'done') {
      if (info.file.response) {
        const c = info.file.response.colaboradores.map((calculado) =>
          calcular(calculado, info.file.response.regulamento)
        );
        setStateBeneficios({ ...beneficios, colaboradores: [...c] });

        Modal.success({
          title: 'Sucesso',
          content: <p>Benefícios importados com sucesso!</p>,
        });

        setLoading({ ...loading, importar: false });
      }
    } else if (info.file.status === 'error') {
      Modal.error({
        title: 'Erro',
        content: <p>{info.file.response.message}</p>,
      });

      setLoading({ ...loading, importar: false });
    }
  };

  // ------------- Funções de totalizador da tabela ------
  const [eventosTotalizadores, setEventosTotalizadores] = useState([]);
  const colunas = ['refeicao_dia', 'valor_refeicao', 'tranfere_para_alimentacao', 'valor_alimentacao'];

  /**
   * Efeito para validar o total das colunas dos colaboradores selecionados
   */
  useEffect(() => {
    if (beneficios && beneficios.colaboradores) {
      const colunasSomadas = colunas.map((coluna) => ({
        coluna,
        total: beneficios.colaboradores.reduce((acc, colab) => {
          const { valor } = colab.eventos.find((e) => e.coluna === coluna);

          return acc + (valor || 0);
        }, 0),
      }));

      setEventosTotalizadores(colunasSomadas);

      setTotal(
        colunasSomadas
          .filter((c) => c.coluna === 'valor_refeicao' || c.coluna === 'valor_alimentacao')
          .reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)
      );
    }
  }, [beneficios]);

  const acesso = useContext(AuthContext);

  const { mostrar, mostrarModalReabertura, mostrarModalComplementar } = useMostrarModal(acesso);

  const validacao = useValidacaoLancamento(
    beneficios.cabecalho,
    acesso,
    beneficios.regulamento ? beneficios.regulamento.dia_limite_lancamento : 0,
    competencias,
    competencia,
    existemColaboradoresExcedentes(beneficios)
  );

  const onRequisicaoApuracaoDias = async () => {
    setLoading({ ...loading, apuracaoDias: true });
    try {
      await apurarDiasFolha(folha, competencia);
      await doRequestBeneficios();
    } finally {
      setLoading({ ...loading, apuracaoDias: false });
    }
  };

  /**
   * Exporta os dados da tabela de lançamento de beneficios.
   */
  const onExportar = async () => {
    setLoading({ ...loading, exportar: true });

    if (!validacao || validacao.permiteEditar) {
      await onSendBeneficios('salvar', false);
    }

    const selecionados = beneficios.colaboradores.map((itensCalculo) => itensCalculo.colaborador.funcCodigo);

    const model = {
      idEmpresa: empresa,
      idLocal: local,
      idFolha: folha,
      competencia: competencia.format('MM/YYYY'),
      selecionados,
      regulamento: beneficios && beneficios.regulamento ? beneficios.regulamento : {},
    };

    imprimir(model, '/beneficios/lancamento/exportar', `${nomeArquivoExcel}.xls`)
      .then(() => {
        Modal.success({
          title: 'Sucesso',
          content: <p>Benefícios exportados com sucesso!</p>,
        });

        setLoading({ ...loading, exportar: false });
      })
      .catch((e) => {
        if (e.request.status === 400) {
          Modal.error({
            content: e.response.data.message,
          });
        }

        setLoading({ ...loading, exportar: false });
      });
  };

  useEffect(() => {
    if (folha !== undefined && folha !== null && competencia !== undefined && competencia !== null) {
      const razaoSocialEmpresa = empresas
        .filter((emp) => emp.idEmpresa === empresa)[0]
        .razaoSocial.replace(/[^\w\s]/g, '_');
      const razaoSocialFolha = folhas.filter((fol) => fol.idFolha === folha)[0].descricao.replace(/[^\w\s]/g, '_');
      const razaoSocialLocal = locais.filter((loc) => loc.idLocal === local)[0].descricao.replace(/[^\w\s]/g, '_');
      const nomeArquivo = `${moment(competencia).format(
        'MM_YYYY'
      )}_Mens_${razaoSocialEmpresa}_${razaoSocialLocal}_${razaoSocialFolha}`;
      setNomeArquivoExcel(nomeArquivo);
    }
  }, [folha, competencia]);

  let conteudoTabela;

  if (!competencia) {
    conteudoTabela = (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 16, offset: 4 }}
          xxl={{ span: 12, offset: 5 }}
        >
          <PreviewTela
            imagem={imagem}
            title="Lançamentos de Alimentação / Refeição"
            subTitle="Aqui você poderá consultar, editar e validar os pedidos dos benefícios: “Alimentação e Refeição” aos colaboradores."
          />
        </Col>
      </Row>
    );
  } else if (competencia && loadingColaboradores) {
    conteudoTabela = (
      <>
        <Skeleton
          active
          avatar
          paragraph={{ rows: 3 }}
        />
        <Skeleton
          active
          avatar
          paragraph={{ rows: 3 }}
        />
        <Skeleton
          active
          avatar
          paragraph={{ rows: 3 }}
        />
      </>
    );
  } else if (beneficios && !beneficios.regulamento) {
    const folhaSelecionada = (folhas || []).find((f) => f.idFolha === folha);
    conteudoTabela = (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 16, offset: 4 }}
          xxl={{ span: 12, offset: 5 }}
        >
          <PreviewTela
            imagem={imagem}
            title={`A folha ${folhaSelecionada ? folhaSelecionada.descricao : ''} não possui regulamento cadastrado.`}
            subTitle={
              <Button
                onClick={() => {
                  history.push('/regulamento');
                }}
              >
                Cadastrar um regulamento
              </Button>
            }
          />
        </Col>
      </Row>
    );
  } else if (beneficios && beneficios.colaboradores && beneficios.colaboradores.length === 0) {
    conteudoTabela = (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ span: 16, offset: 4 }}
          xxl={{ span: 12, offset: 5 }}
        >
          <PreviewTela
            imagem="https://storage.googleapis.com/static-files-4fdd9378-835d-40e4-b124-36699ae5cc08/banners/semdados.png"
            title="Ops!"
            subTitle="Não foram encontrados dados para a sua busca."
          />
        </Col>
      </Row>
    );
  } else {
    conteudoTabela = (
      <>
        <TabelaBeneficios
          colaboradores={colaboradoresFiltrados.itens}
          regulamentos={beneficios && beneficios.regulamento ? beneficios.regulamento : {}}
          eventosTotalizadores={eventosTotalizadores}
          calcular={calcularManual}
          permiteEditar={validacao.permiteEditar}
        />

        {validacao.mensagem && (
          <div
            style={{
              display: 'flex',
              marginTop: '16px',
              justifyContent: 'flex-end',
            }}
          >
            <Alert
              type="error"
              message={<div>{validacao.mensagem}</div>}
            />
          </div>
        )}

        <Row
          type="flex"
          style={{ marginTop: '16px' }}
        >
          {beneficios && beneficios.colaboradores && beneficios.colaboradores.length > 0 && (
            <Col span={24}>
              <>
                {beneficios && beneficios.dataAtualizacaoApuracaoDias ? (
                  <>
                    <CustomText
                      fontSize="12px"
                      color="var(--text-grey-6)"
                    >
                      Última atualização:{' '}
                      {moment.utc(beneficios.dataAtualizacaoApuracaoDias).format('DD/MM/YYYY [às] HH:mm')}{' '}
                    </CustomText>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => onRequisicaoApuracaoDias()}
                      loading={loading.apuracaoDias}
                      style={{ textAlign: 'initial' }}
                    >
                      <CustomText
                        textDecoration="underline"
                        color="var(--text-grey-6)"
                      >
                        Buscar
                      </CustomText>
                    </Button>
                  </>
                ) : (
                  <>
                    <CustomText
                      fontSize="12px"
                      color="var(--text-grey-6)"
                    >
                      Não há dados para apuração
                    </CustomText>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => onRequisicaoApuracaoDias()}
                      loading={loading.apuracaoDias}
                      style={{ textAlign: 'initial' }}
                    >
                      <CustomText
                        textDecoration="underline"
                        color="var(--text-grey-6)"
                      >
                        Buscar
                      </CustomText>
                    </Button>
                  </>
                )}
                {loading.apuracaoDias && (
                  <p style={{ marginTop: '10px' }}>
                    Esta atualização pode levar mais de 5 minutos. Aproveite para tomar um café.
                    <span
                      role="img"
                      aria-label=":)"
                    >
                      &#128578;
                    </span>
                  </p>
                )}
              </>
            </Col>
          )}
        </Row>

        <Row
          type="flex"
          style={{ marginTop: '16px' }}
        >
          <Col>
            <Pagination
              current={paginacao.paginaAtual}
              defaultPageSize={paginacao.itensPorPagina}
              hideOnSinglePage
              total={colaboradoresFiltrados.total}
              onChange={alterarPaginacao}
              onShowSizeChange={alterarPaginacao}
              pageSizeOptions={[8, 15, 30, 50]}
              showSizeChanger
            />
          </Col>

          <Col
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              columnGap: 16,
              marginLeft: 'auto',
            }}
          >
            {validacao.permiteEditar && validacao.habilitarFinalizar && (
              <Button
                type="primary"
                onClick={async () => {
                  setLoading({ ...loading, finalizar: true });
                  await onSendBeneficios('salvar', false);
                  await onSendBeneficios('finalizar');
                  setLoading({ ...loading, finalizar: false });
                }}
                loading={loading.finalizar}
              >
                Finalizar
              </Button>
            )}

            {validacao.permiteEditar && (
              <Button
                onClick={() => onSendBeneficios('salvar')}
                loading={loading.salvar}
              >
                Salvar
              </Button>
            )}

            <Button
              onClick={onExportar}
              loading={loading.exportar}
            >
              Exportar
            </Button>

            {validacao.permiteEditar && (
              <Upload
                accept=".xls"
                name="file"
                action={urlbaseBackend('/beneficios/lancamento/importar')}
                showUploadList={false}
                beforeUpload={() => setLoading({ ...loading, importar: true })}
                onChange={onImportar}
                headers={{
                  ...auth,
                  empresa,
                  local,
                  folha,
                  competencia,
                }}
              >
                <Button
                  fontSize="12px"
                  height="16px"
                  color="var(--text-grey-8)"
                  loading={loading.importar}
                >
                  Importar
                </Button>
              </Upload>
            )}
            {validacao.habilitarReabertura && (
              <Button
                onClick={() => mostrarModalReabertura(true)}
                loading={loading.reabertura}
              >
                Reabrir
              </Button>
            )}
            <Button
              onClick={() => {
                if (validacao.acessaTelaComplementar) {
                  history.push(`/lancamento/complementar/${beneficios.cabecalho.id}`);
                } else {
                  mostrarModalComplementar(true);
                }
              }}
              loading={loading.complementar}
            >
              Complementar
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <BeneficiosPage titulo="Lançamento de Alimentação e Refeição">
      <Row
        gutter={26}
        type="flex"
        justify="center"
      >
        <Col style={{ borderRight: '2px solid #c1c2c4' }}>
          <Card style={{ padding: 8 }}>
            <CustomText
              fontSize="12px"
              color="var(--text-grey-6)"
            >
              Utilização do Benefício
            </CustomText>
            <CustomText
              fontSize="20px"
              color="var(--text-grey-8)"
              fontWeight="600"
            >
              Dia {informacoesCabecalho.peridoUtilizacao.inicio} até {informacoesCabecalho.peridoUtilizacao.fim}
            </CustomText>
          </Card>
        </Col>
        <Col>
          <Card style={{ padding: 8 }}>
            <CustomText
              fontSize="12px"
              color="#656668"
            >
              Período para Lançamentos
            </CustomText>
            <CustomText
              fontSize="20px"
              color="#262626"
              fontWeight="600"
            >
              dia {informacoesCabecalho.peridoLancamento.inicio} ao dia {informacoesCabecalho.peridoLancamento.fim}
            </CustomText>
          </Card>
        </Col>
        <Col style={{ borderLeft: '2px solid #c1c2c4' }}>
          <Card style={{ padding: 8 }}>
            <CustomText
              fontSize="12px"
              color="#656668"
            >
              Valor total
            </CustomText>
            <CustomText
              fontSize="20px"
              color="#262626"
              fontWeight="600"
            >
              {loadingColaboradores ? <Spin size="small" /> : moneyFormat(total)}
            </CustomText>
          </Card>
        </Col>
      </Row>

      <Row
        type="flex"
        gutter={[16, 16]}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Label label={<CustomLabel8>Empresa</CustomLabel8>}>
            <CustomSelect
              loading={loadingEmpresas}
              placeholder="Selecione uma empresa..."
              allowClear
              value={empresa}
              onChange={(value) => {
                setEmpresa(value);
                setLocal(undefined);
                setFolha(undefined);
                setCompetencia(undefined);
              }}
            >
              {(empresas || []).map((item) => (
                <Option
                  key={item.idEmpresa}
                  value={item.idEmpresa}
                >{`${item.idEmpresa} - ${item.razaoSocial}`}</Option>
              ))}
            </CustomSelect>
          </Label>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
        >
          <Label label={<CustomLabel8>Local</CustomLabel8>}>
            <CustomSelect
              placeholder="Selecione um local..."
              loading={loadingLocais}
              allowClear
              value={local}
              onChange={(value) => {
                setLocal(value);
                setFolha(undefined);
                setCompetencia(undefined);
              }}
            >
              {(locais || []).map((item) => (
                <Option
                  key={item.idLocal}
                  value={item.idLocal}
                >{`${item.idLocal} - ${item.descricao}`}</Option>
              ))}
            </CustomSelect>
          </Label>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
        >
          <Label label={<CustomLabel8>Folha</CustomLabel8>}>
            <CustomSelect
              placeholder="Selecione uma folha..."
              loading={loadingFolhas}
              allowClear
              value={folha}
              onChange={(value) => {
                setFolha(value);
                setCompetencia(undefined);
                setPaginacao(initialStatePaginacao);
              }}
            >
              {(folhas || []).map((item) => (
                <Option
                  key={item.idFolha}
                  value={item.idFolha}
                >{`${item.idFolha} - ${item.descricao}`}</Option>
              ))}
            </CustomSelect>
          </Label>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
        >
          <Label label={<CustomLabel8>Competência</CustomLabel8>}>
            <Select
              placeholder="MM/YYYY"
              loading={loadingCompetencias}
              value={competenciaFormatada}
              allowClear={false}
              onChange={(value) => {
                setCompetencia(moment(value, 'MM/YYYY'));
                setPaginacao(initialStatePaginacao);
              }}
              suffixIcon={<CalendarOutlined />}
            >
              {(competencias || []).map((item) => {
                const cpt = moment(`${item.mes}/${item.ano}`, 'M/YYYY').format('MM/YYYY');
                return (
                  <Option
                    key={cpt}
                    value={cpt}
                  >
                    {cpt}
                  </Option>
                );
              })}
            </Select>
          </Label>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={15}
          xl={10}
        >
          <Label label={<CustomLabel8>Filtrar por nome</CustomLabel8>}>
            <Input
              type="text"
              value={filtroNome}
              onChange={(e) => setFiltroNome(e.target.value)}
              placeholder="Nome do colaborador"
              allowClear
            />
          </Label>
        </Col>
      </Row>

      <Row
        type="flex"
        style={{ marginTop: '15px' }}
      >
        {beneficios.regulamento && beneficios.regulamento.nome_regulamento && acesso.isAdmin() && (
          <Col span={24}>
            <>
              <CustomText
                fontSize="14px"
                color="var(--text-grey-8)"
              >
                Regulamento vigente:
              </CustomText>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/regulamento/${beneficios.regulamento.id}`}
                style={{ fontSize: '14px', marginLeft: '8px' }}
              >
                {beneficios.regulamento.nome_regulamento}
              </a>
            </>
          </Col>
        )}

        <Col
          span={24}
          style={{ marginTop: '8px' }}
        >
          {conteudoTabela}
        </Col>
      </Row>

      <ModalReaberturaGestor
        visible={mostrar.modalReaberturaGestor}
        onCancel={() => mostrarModalReabertura(false)}
        onOk={(observacao) =>
          reabrirGestor(
            empresa,
            local,
            folha,
            competencia,
            observacao,
            beneficios.cabecalho.dataFinalizacao,
            loading,
            setLoading,
            () => {
              doRequestBeneficios();
              mostrarModalReabertura(false);
            }
          )
        }
        loading={loading.reabertura}
      />
      <ModalReaberturaAdmin
        visible={mostrar.modalReaberturaAdmin}
        onCancel={() => mostrarModalReabertura(false)}
        onOk={(dataLimite) =>
          reabrirAdmin(empresa, local, folha, competencia, dataLimite, loading, setLoading, () => {
            doRequestBeneficios();
            mostrarModalReabertura(false);
          })
        }
        loading={loading.reabertura}
      />
      <ModalPedidoComplementarAdmin
        visible={mostrar.modalComplementarAdmin}
        onCancel={() => mostrarModalComplementar(false)}
        onOk={(dataLimite, emailAlerta) =>
          liberarPedidoComplementar(
            empresa,
            local,
            folha,
            competencia,
            dataLimite,
            emailAlerta,
            loading,
            setLoading,
            () => mostrarModalComplementar(false)
          )
        }
        loading={loading.complementar}
        emailAlerta={beneficios && beneficios.regulamento && beneficios.regulamento.emailAlerta}
      />
    </BeneficiosPage>
  );
}

LancamentoBeneficios.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      empresa: PropTypes.string,
      local: PropTypes.string,
      folha: PropTypes.string,
    }),
  }),
};

LancamentoBeneficios.defaultProps = {
  match: { params: {} },
};

export default LancamentoBeneficios;
