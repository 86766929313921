/* eslint-disable max-len */
import ticketStore from './eventos';

const colunasTicket = ticketStore.colunas;

/**
 * O colaborador a ser calculado.
 * @typedef {Object} Colaborador
 * @property {"A"|"F"|"D"|"R"} situacao - A situação do colaborador (A = Admitido, F = em Férias, D = Demitido, R = Retorno de férias)
 * @property {number} diasTrabalhados - Dias trabalhados do colaborador na competencia
 * @property {number} valorTA - Valor de Alimentação solicitado pela empresa ou pelo colaborador
 * @property {boolean} temTransferenciaMesAnterior - Se o colaborador de valor de transferência de TR para TA na competência anterior
 */

/**
 * Regulamento Ticket
 * @typedef {Object} Regulamento
 * @property {number} id - id do regulamento
 * @property {number} id_empresa - id da empresa em que o regulamento foi cadastrado
 * @property {number} id_local - id do local em que o regulamento foi cadastrado
 * @property {number} id_folha - id do folha em que o regulamento foi cadastrado
 * @property {string} nome_regulamento - Nome do regulamento
 * @property {date} periodo_inicial - data inicial para lancamento cadastrado no regulamento
 * @property {date} periodo_final - data final para lancamento cadastrado no regulamento
 * @property {boolean} solicita_vr - Se o colaborador solicita vr
 * @property {number} solicita_vr_valor - Valor padrão para vr caso o campo solicita_vr seja verdadeiro
 * @property {boolean} solicita_va - Se o colaborador solicita va
 * @property {number} valorSolicitacaoVARegulamento - Valor padrão para vr caso o campo solicita_va seja verdadeiro
 * @property {boolean} considera_dias_fixos - Se considera dias fixos
 * @property {number} qtd_dias_fixos - Quantidade de dias fixos caso "considera_dias_fixos" seja verdadeiro
 * @property {boolean} proporcionaliza_ferias - Se proporcionaliza férias
 * @property {boolean} considera_prog_ferias - Se considera programação nas férias
 * @property {boolean} considera_prog_afastamento - Se considera programação nos afastamentos
 * @property {boolean} proporcionaliza_afastamento - Se proporcionaliza afastamento
 * @property {boolean} proporcionaliza_rescisao - Se proporcionaliza rescisão
 * @property {boolean} vr_diferente_colab - Se vr é diferente entre colaboradores
 * @property {boolean} trans_vr_para_va - Se tranfere vr para va
 * @property {boolean} comp_dif_colab - Composição diferente entre colaboradores
 * @property {date} data - data de cadastro do regulamento
 */

/**
 * Eventos do Ticket
 * @typedef {{coluna: string, valor: ?number}} Evento
 */

class CalculoTicket {
  constructor(colaborador, regulamento, eventos) {
    this.regulamento = regulamento || {};
    this.colaborador = colaborador;
    this.eventos = eventos;
  }

  get({ coluna }) {
    return this.getEvento(coluna).valor;
  }

  set({ coluna }, valor) {
    this.getEvento(coluna).valor = valor;
  }

  naoTemValor({ coluna }) {
    return this.getEvento(coluna).valor === undefined || this.getEvento(coluna).valor === null;
  }

  temValor(evento) {
    return !this.naoTemValor(evento);
  }

  setDefault(evento, valor) {
    if (this.get(evento) === null || this.get(evento) === undefined) {
      this.set(evento, valor || 0);
    }
  }

  inicializarVariaveis() {
    this.setDefault(colunasTicket.dias_adicionais);
    this.setDefault(colunasTicket.dias_desconto);
    this.setDefault(colunasTicket.tranfere_para_alimentacao);
    this.colaborador.diasTrabalhados = this.colaborador.diasTrabalhados || 0;
    this.colaborador.diasFerias = this.colaborador.diasFerias || 0;
    this.colaborador.diasFeriado = this.colaborador.diasFeriado || 0;
    this.colaborador.diasFeriasProgramadas = this.colaborador.diasFeriasProgramadas || 0;
    this.colaborador.diasDemitido = this.colaborador.diasDemitido || 0;
    this.colaborador.diasUteis = this.colaborador.diasUteis || 0;
    this.regulamento.solicita_va_valor = this.regulamento.solicita_va_valor || 0;

    return {
      valorSolicitacaoVARegulamento: this.regulamento.solicita_va_valor,
    };
  }

  calcular() {
    const { valorSolicitacaoVARegulamento } = this.inicializarVariaveis();

    const creditoDias = this.get(colunasTicket.dias_adicionais) - this.get(colunasTicket.dias_desconto);

    const {
      // diasUteis,
      // diasTrabalhados,
      diasFerias,
      // diasFeriado,
      diasFeriasProgramadas,
      diasDemitido,
      diasSemFerias,
      diasSemFeriasProgramadas,
      diasFeriadoTrabalhados,
    } = this.colaborador;

    let valorAlimentacaoInicial;

    if (this.colaborador.valorTA === null) {
      this.colaborador.valorTA = 0;
    }

    if (this.regulamento.solicita_va) {
      valorAlimentacaoInicial = valorSolicitacaoVARegulamento + this.get(colunasTicket.tranfere_para_alimentacao);
    } else if (this.regulamento.trans_vr_para_va) {
      valorAlimentacaoInicial = this.colaborador.valorTA + this.get(colunasTicket.tranfere_para_alimentacao);
    } else {
      valorAlimentacaoInicial = this.colaborador.valorTA - this.get(colunasTicket.tranfere_para_alimentacao);
    }

    let valorInicialRefeicaoDia;

    if (this.regulamento.solicita_vr) {
      valorInicialRefeicaoDia = this.regulamento.solicita_vr_valor;
    } else {
      valorInicialRefeicaoDia =
        this.get(colunasTicket.valor_refeicao) - this.get(colunasTicket.tranfere_para_alimentacao);
    }

    if (this.colaborador.rascunhoEstaEmBranco === true) {
      this.getEvento(colunasTicket.refeicao_dia.coluna).valor = valorInicialRefeicaoDia;

      if (this.colaborador.temTransferenciaMesAnterior) {
        this.getEvento(colunasTicket.tranfere_para_alimentacao.coluna).valor = this.getEvento(
          colunasTicket.valor_refeicao.coluna
        ).valor;
        this.getEvento(colunasTicket.valor_alimentacao.coluna).valor = this.getEvento(
          colunasTicket.valor_refeicao.coluna
        ).valor;
        this.getEvento(colunasTicket.valor_refeicao.coluna).valor = 0;
      }
    }

    /*
      https://4beebwg.atlassian.net/browse/FP2-1893

      Quando a situação do colaborador for demitido e a data de demissão se referir ao mês de lançamento,
      os valores de Refeição dia / Transferencia e Alimentação deverão ser = 0,00.

      A mesma regra servirá ao colaborador ativo, com data de demissão referente ao mês de carregamento.
     */
    if (diasDemitido > 0) {
      this.set(colunasTicket.refeicao_dia, 0.0);
      this.set(colunasTicket.valor_refeicao, 0.0);
      this.set(colunasTicket.valor_alimentacao, 0.0);
      this.set(colunasTicket.tranfere_para_alimentacao, 0.0);
    }

    let quantidadeDias;
    if (
      this.regulamento.proporcionaliza_ferias &&
      this.regulamento.considera_prog_ferias &&
      (diasFeriasProgramadas || 0) > 0
    ) {
      quantidadeDias = diasSemFeriasProgramadas;
    } else if (this.regulamento.proporcionaliza_ferias && (diasFerias || 0) > 0) {
      // ferias efetivadas apenas
      quantidadeDias = diasSemFerias;
    } else if (this.regulamento.considera_dias_fixos) {
      quantidadeDias = this.regulamento.qtd_dias_fixos;
    } else {
      quantidadeDias = diasFeriadoTrabalhados;
    }

    this.getEvento(colunasTicket.valor_refeicao.coluna).valor =
      this.getEvento(colunasTicket.refeicao_dia.coluna).valor * (quantidadeDias + creditoDias);

    this.set(colunasTicket.quantidade_dias, quantidadeDias);

    if (this.naoTemValor(colunasTicket.valor_alimentacao)) {
      this.set(colunasTicket.valor_alimentacao, valorAlimentacaoInicial);
    }

    if (this.get(colunasTicket.valor_alimentacao) !== valorAlimentacaoInicial) {
      // valor foi forçado ou esta diferente por algum motivo
      this.set(colunasTicket.valor_alimentacao_forcado, this.get(colunasTicket.valor_alimentacao));
    } else {
      this.set(colunasTicket.valor_alimentacao_forcado, null);
      this.getEvento(colunasTicket.valor_alimentacao.coluna).valor = valorAlimentacaoInicial;
    }

    this.getEvento(colunasTicket.valor_refeicao.coluna).valor -= this.get(colunasTicket.tranfere_para_alimentacao);

    if (this.getEvento(colunasTicket.valor_refeicao.coluna).valor < 0) {
      this.getEvento(colunasTicket.valor_refeicao.coluna).valor = 0;
    }

    if (this.colaborador.rascunhoEstaEmBranco === true && this.colaborador.temTransferenciaMesAnterior) {
      this.getEvento(colunasTicket.tranfere_para_alimentacao.coluna).valor = this.getEvento(
        colunasTicket.valor_refeicao.coluna
      ).valor;
      this.getEvento(colunasTicket.valor_alimentacao.coluna).valor = this.getEvento(
        colunasTicket.valor_refeicao.coluna
      ).valor;
      this.getEvento(colunasTicket.valor_refeicao.coluna).valor = 0;
    }

    this.colaborador.rascunhoEstaEmBranco = false;
    this.colaborador.temTransferenciaMesAnterior = false;
  }

  /**
   * Busca evento pelo código ou pelo código do evento
   * @param {string} coluna - nome da coluna no eventos.js
   * @returns {Evento}
   */
  getEvento(coluna) {
    const evento = this.eventos.find((e) => e.coluna === coluna);

    if (!evento) {
      const eventoLimpo = { coluna };
      this.eventos.push(eventoLimpo);
      return eventoLimpo;
    }

    return evento;
  }
}
export const Calculo = CalculoTicket;
export const eventos = ticketStore;
// export default { Calculo: CalculoTicket, eventos: ticketStore };
