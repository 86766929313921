import React, { useState } from 'react';
import { Button, DatePicker, Input, Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Label } from '@4beefopag/4bee-fopag-core/dist/atoms';

const TextoModal = styled.p`
  font-size: 12px;
  color: #a3a4a5;
`;

const ModalStyled = styled(Modal)`
  .ant-modal-footer {
    border-top: unset;
  }
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    font-size: 16px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }
`;

const ModalPedidoComplementarAdmin = ({ visible, onOk, onCancel, loading, emailAlerta }) => {
  const [dataLimite, setDataLimite] = useState(moment.utc());
  const [stateEmailAlerta, setStateEmailAlerta] = useState(emailAlerta);

  return (
    <ModalStyled
      open={visible}
      title="Pedido Complementar"
      onOk={onOk}
      onCancel={onCancel}
      closeable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => onOk(dataLimite, stateEmailAlerta)}
          loading={loading}
        >
          Enviar
        </Button>,
        <Button
          key="back"
          onClick={onCancel}
          disabled={loading}
        >
          Cancelar
        </Button>,
      ]}
    >
      <TextoModal>
        Para liberar o pedido complementar, informe a data e horário limite para finalizar o lançamento:
      </TextoModal>
      <DatePicker
        showTime
        placeholder="DD/MM/YYYY HH:mm"
        format="DD/MM/YYYY HH:mm"
        value={dataLimite}
        onChange={(data) => setDataLimite(data)}
        style={{ marginBottom: '10px' }}
      />
      <Label label="E-mails para alerta">
        <Input
          value={stateEmailAlerta}
          onChange={(e) => {
            setStateEmailAlerta(e.target.value);
          }}
        />
      </Label>
    </ModalStyled>
  );
};

ModalPedidoComplementarAdmin.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  emailAlerta: PropTypes.string,
};

ModalPedidoComplementarAdmin.defaultProps = {
  visible: false,
  emailAlerta: '',
};

export default ModalPedidoComplementarAdmin;
