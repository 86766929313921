import { defaultGet } from '@4beefopag/4bee-fopag-core/dist/services';

export const buscarRegulamentos = async (params) => {
  if (params.idEmpresa) {
    return defaultGet(params, '/regulamento');
  }

  return [];
};

export const buscarRegulamento = async (idEmpresa, idLocal, idFolha, vigencia) => {
  if (idFolha !== null && idFolha !== undefined) {
    const res = await defaultGet(
      {
        idEmpresa,
        idLocal,
        idFolha,
        vigencia,
      },
      '/regulamento/unico'
    );

    return res;
  }

  return {};
};

export const buscarRegulamentoPorId = async (id) => defaultGet({}, `/regulamento/${id}`);
