/**
 * @typedef Cabecalho
 * @property {number} status
 * @property {string} dataLimite
 */

/**
 * @typedef Cabecalho
 * @property {number} status
 * @property {string} dataLimite
 */

import moment from 'moment';

/**
 * Hook que valida componentes do lançamento de ticket
 * @param {Cabecalho} cabecalho
 * @param {AcessoUsuario} acesso
 * @param {number} diaLimiteLancamento
 * @param {[]} competencias
 * @param {moment.Moment} competencia
 * @param {boolean} existemColaboradoresExcedentes
 * @returns {[{habilitarReabertura: boolean,
 *            permiteEditar: boolean,
 *            habilitarFinalizar: boolean
 *            acessaTelaComplementar: boolean
 *            }]}
 */
export default (cabecalho, acesso, diaLimiteLancamento, competencias, competencia, existemColaboradoresExcedentes) => {
  let habilitarFinalizar = true;
  let habilitarReabertura = false;
  let permiteEditar = false;
  let acessaTelaComplementar = false;

  let mensagem = '';

  const status = {
    rascunho: !cabecalho || cabecalho.status === 0,
    finalizado: cabecalho && cabecalho.status === 1,
    solicitadaReabertura: cabecalho && cabecalho.status === 2,
    reaberto: cabecalho && cabecalho.status === 3,
    dataLimite: cabecalho && moment.utc(cabecalho.dataLimite, 'YYYY-MM-DD HH:mm').isBefore(moment().utc()),
    dataFinalizacao: cabecalho && cabecalho.dataFinalizacao !== undefined && cabecalho.dataFinalizacao !== null,
    complementarLiberado: cabecalho && cabecalho.statusComplementar !== null,
  };

  if (competencia !== undefined && competencias !== undefined) {
    // const competenciaAnterior = competencia && competencias && competencias[0]
    //   && competencia.month() + 1 === competencias[0].mes;
    const competenciaPosterior =
      competencia && competencias && competencias[2] && competencia.month() + 1 === competencias[2].mes;

    if (competenciaPosterior) {
      habilitarFinalizar = false;
      mensagem = 'Não é possível finalizar em uma competência futura.';
    }

    if (existemColaboradoresExcedentes) {
      habilitarFinalizar = false;
      mensagem = 'A quantidade de dias de desconto não pode superar os dias trabalhados.';
    }
  }

  // permite editar se os dados estiverem em rascunho ou a tela reaberta
  if (status.rascunho || status.reaberto) {
    permiteEditar = true;
  }

  // verifica data limite para lançamento
  if (acesso.isGestor() && status.reaberto && status.dataLimite) {
    mensagem = `Não é possível editar, a data limite para lançamentos era até ${moment(
      cabecalho.dataLimite,
      'DD/MM/YYYY [às] HH:mm'
    )}.`;

    permiteEditar = false;
  }

  // verifica o dia limite para lançamentos
  if (
    (status.rascunho || status.solicitadaReabertura) &&
    diaLimiteLancamento &&
    (moment().date() > diaLimiteLancamento || moment().date() < 10) &&
    acesso.isGestor()
  ) {
    mensagem = `Não é possível editar, o período para lançamentos é do dia 10 ao dia ${diaLimiteLancamento}.`;

    permiteEditar = false;
  }

  // se existir data de finalização, o botão reabertura sera habilitado
  if (status.dataFinalizacao) {
    habilitarReabertura = true;
  }

  if (acesso.isGestor() && status.solicitadaReabertura && status.dataFinalizacao) {
    habilitarReabertura = false;
  }

  if (status.complementarLiberado) {
    acessaTelaComplementar = true;
  }

  return {
    habilitarFinalizar,
    habilitarReabertura,
    permiteEditar,
    acessaTelaComplementar,
    mensagem,
  };
};
